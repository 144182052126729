import React from 'react';
import DetailBox from './DetailBox';

const MemoizedDetailBoxes =  ({products, Unit, category}) => {
  const renderDetailBoxes = () => {

    return products.map((product) => {
      const billede_id = product.producentUploadData && Array.isArray(product.producentUploadData.imageURLS) && product.producentUploadData.imageURLS.length > 0
      ? product.producentUploadData.imageURLS[0].url 
      : null;

      
   
        
      return (
        <DetailBox
          key={`${product.productName}-${product.subCategory}-${product.id}`}  
          itemId={product.id}
          data={product}
          category={category}
          SubCategoryAndId={product.subCategory}
          billede_id={billede_id}
          Unit={Unit}
          frontpage_image={product.epdFrontPageURL}
        />
      );
    });
  };

  return (
    <div>
      {renderDetailBoxes()}
    </div>
  );
};

export default MemoizedDetailBoxes;
