import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { AiOutlineFilePdf } from 'react-icons/ai';
import styles from './searchCard.module.css';

// For product search results (unchanged)
export const SearchCard = ({ index, data }) => {
  const navigate = useNavigate();
  // One-liner to safely retrieve the image URL
  const imageUrl = data?.imageUrls?.[0]?.url || data?.epdFrontPageURL?.url;
  const handleClick = () => {
    // For products, navigate to the product page.
    navigate(`/produkt/${data.id}?from=search`);
  };

  return (
    <li
      className={styles.foundProductItem}
      key={index}
      onClick={handleClick}
    >
      <img
        src={imageUrl}
        alt={data.name || "Product Image"}
        className={styles.img}
      />
      <div className={styles.productInfo}>
        <span
          title={data.productName}
          className={styles.productName}
        >
          {data.productName}
        </span>
        <p className={styles.producerName}>{data.producerName}</p>
        <a
          href={data.epdLink}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.linkStyle}
          onClick={(e) => e.stopPropagation()}
        >
          <AiOutlineFilePdf style={{ fontSize: '24px', color: 'red' }} />
          <p className={styles.productLink}>{data.epdid}</p>
        </a>
      </div>
    </li>
  );
};

// New component for producer search results (for strings)
export const ProducerSearchCard = ({ index, producer }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    // Navigate to products filtered by the selected producer.
    navigate(`/producer/${encodeURIComponent(producer)}`);
  };

  return (
    <li
      className={styles.foundProductItem}
      key={index}
      onClick={handleClick}
    >
      <div className={styles.productInfo}>
        <span className={styles.productName}>{producer}</span>
      </div>
    </li>
  );
};

export const SearchBarPopup = ({ query, search }) => {
  const ulRef = useRef(null);
  const timeoutRef = useRef(null); // For debouncing
  const location = useLocation();
  const [data, setData] = useState({ manualTypedData: [] });
  const [offset, setOffset] = useState(0);
  const [currentSearch, setCurrentSearch] = useState(search);
  const isSubkategori = location.pathname.includes("/subkategori/");
  const isProducer = location.pathname.includes("/producer");
  const isCategory = location.pathname.includes("/kategori") && !new URLSearchParams(location.search).get("type");
  const { id } = useParams();
  const category  = id;
  
  // console.log("isSubkategori: ", isSubkategori, " isProducer: ", isProducer, " isCategory: ", isCategory);
    // `${baseUrl}/Products/GetProductsRegex?query=${encodeURIComponent(first)}&offset=${offset}&isSubcategory=${isSubcategory}&isProducer=${isProducer}&isCategory=${isCategory}&regexp=${encodeURIComponent(reg)}`,

      useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const fetchData = async () => {
        try {
          if (search !== currentSearch) {
            setOffset(0);
            setData({ manualTypedData: [] });
            setCurrentSearch(search);
          }
          const result = await query(search, category, 0, isSubkategori, isProducer, isCategory);
          setData({ manualTypedData: result || [] });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }, 5); // Minimal 

    return () => clearTimeout(timeoutRef.current);
  }, [query, search, category, currentSearch]);

  // Effect for handling pagination (offset changes)
  useEffect(() => {
    if (offset === 0) return; // Do not fetch on initial render

    const fetchMoreData = async () => {
      try {
        const result = await query(search, offset, category, isSubkategori, isProducer, isCategory);
        setData((prevData) => ({
          manualTypedData: [
            ...(prevData.manualTypedData || []),
            ...(result || [])
          ]
        }));
      } catch (error) {
        console.error("Error fetching paginated data:", error);
      }
    };

    fetchMoreData();
  }, [offset, query, search]);

  // Infinite scrolling handler
  const handleScroll = () => {
    const ulElement = ulRef.current;
    if (ulElement) {
      const { scrollTop, clientHeight, scrollHeight } = ulElement;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        setOffset((prevOffset) => prevOffset + 10);
      }
    }
  };

  return (
    <ul ref={ulRef} className={styles.ulstyle} onScroll={handleScroll}>
      {data.manualTypedData.length > 0 ? (
        data.manualTypedData.map((item, index) =>
          // If the item is a string (producer name), use ProducerSearchCard.
          typeof item === "string" ? (
            <ProducerSearchCard
              key={index}
              index={index}
              producer={item}
            />
          ) : (
            // Otherwise, assume it's a product-like object.
            <SearchCard
              key={item.id || index}
              index={index}
              data={item}
            />
          )
        )
      ) : (
        // You can show a "no results" message or nothing.
        null
      )}
    </ul>
  );
};
