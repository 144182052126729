import React from 'react';
import { useNavigate } from "react-router-dom";
import styles from './RegisterPage.module.css';
import { PiStudentFill } from "react-icons/pi";
import { LiaUserTieSolid } from "react-icons/lia";

function RegisterPage() {
    const navigate = useNavigate();

    const licenseTypes = [
        { value: 'student', label: 'Studerende', icon: <PiStudentFill className={styles.iconStyle} />, path: '/register/student' },
        // { value: 'normal', label: 'Arkitekt eller Ingeniør', icon: <LiaUserTieSolid className={styles.iconStyle} />, path: '/register/arkeng' }
    ];

    const handleLicenseSelection = (path) => {
        navigate(path);
    };

    return (
        <div className={styles.pageStyle}>
            <div className={styles.licenseCard}>
                <div className={styles.licenseCardHeader}>
                    {/* <h3>Vælg licens</h3> */}
                </div>
                <div className={styles.licenseCardOptions}>
                    {licenseTypes.map((license) => (
                        <div
                            key={license.value}
                            className={styles.licenseCardOption}
                            onClick={() => handleLicenseSelection(license.path)}
                        >
                            {license.icon}
                            {license.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default RegisterPage;
