import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Styles from './RegisterForm.module.css';
import StyledButton from '../../components/StyledButton';
import { registerAccount, registerUnderAccount, emailSignup } from '../../components/APIS/registerAPI';
import { form } from 'framer-motion/client';
import { baseUrl, useGlobalContext } from '../../GlobalContext';




const schools = [
    "University of Copenhagen",
    "Technical University of Denmark (DTU)",
    "Copenhagen Business School",
    "Aarhus University",
    "Roskilde University",
    "Aalborg University",
    "University of Southern Denmark",
    "IT University of Copenhagen",
    "KEA - Copenhagen School of Design and Technology",
    "Danish School of Media and Journalism",
    "University College Absalon",
    "Metropolitan University College",
    "University College Copenhagen",
    "University College Lillebaelt",
    "University College of Northern Denmark",
    "VIA University College",
    "University College South Denmark",
    "IBA International Business Academy",
    "Business Academy Aarhus",
    "Business Academy MidWest",
    "Copenhagen Business Academy",
    "EA Business Academy SouthWest",
    "Lillebaelt Academy",
    "Zealand Institute of Business and Technology",
    "Royal Danish Academy of Fine Arts",
    "Royal Danish Academy of Music",
    "Royal Academy of Music",
    "Aarhus School of Architecture",
    "The National Film School of Denmark",
    "Design School Kolding",
    "Rhythmic Music Conservatory",
    "Danish National Academy of Music",
    "The Danish National School of Performing Arts",
    "Copenhagen School of Marine Engineering and Technology Management",
    "Aarhus School of Marine and Technical Engineering",
    "Fredericia Maskinmesterskole",
    "Other"
]

const schoolsDK = [
    "Københavns Universitet",
    "Danmarks Tekniske Universitet (DTU)",
    "Copenhagen Business School",
    "Aarhus Universitet",
    "Roskilde Universitet",
    "Aalborg Universitet",
    "Syddansk Universitet",
    "IT-Universitetet i København",
    "KEA - Københavns Erhvervsakademi",
    "Danmarks Medie- og Journalisthøjskole",
    "Professionshøjskolen Absalon",
    "Metropol Professionshøjskole",
    "Københavns Professionshøjskole",
    "University College Lillebælt",
    "University College Nordjylland",
    "VIA University College",
    "University College Syddanmark",
    "IBA Erhvervsakademi Kolding",
    "Erhvervsakademi Aarhus",
    "Erhvervsakademi MidtVest",
    "Copenhagen Business Academy",
    "Erhvervsakademi Dania",
    "Erhvervsakademi SydVest",
    "Lillebælt Erhvervsakademi",
    "Zealand Erhvervsakademi",
    "Det Kongelige Akademi – Arkitektur, Design, Konservering",
    "Det Kongelige Danske Musikkonservatorium",
    "Det Jyske Musikkonservatorium",
    "Arkitektskolen Aarhus",
    "Den Danske Filmskole",
    "Designskolen Kolding",
    "Rytmisk Musikkonservatorium",
    "Syddansk Musikkonservatorium",
    "Den Danske Scenekunstskole",
    "Københavns Maskinmesterskole",
    "Aarhus Maskinmesterskole",
    "Fredericia Maskinmesterskole"
]


function RegisterForm({ inputFields, arkeng = false, plus = false, stud = false }) {
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [errors, setErrors] = useState({});
    const [filteredSchools, setFilteredSchools] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const { setUserType } = useGlobalContext();
    const { id } = useParams();

    // Initialize formData from inputFields
    const [formData, setFormData] = useState(() => {
        const initialData = {};
        inputFields.forEach((field) => {
            initialData[field.name] = "";
        });
        return initialData;
    });

    const handleFocus = () => setPasswordFocused(true);
    const handleBlur = () => setPasswordFocused(false);

    // Function to evaluate password strength
    const evaluatePasswordStrength = (password) => {
        let score = 0;
        if (!password) return 0;
        if (password.length > 8) score += 1;
        if (/[a-z]/.test(password)) score += 1;
        if (/[A-Z]/.test(password)) score += 1;
        if (/\d/.test(password)) score += 1;
        if (/[^A-Za-z0-9]/.test(password)) score += 1;
        return score;
    };

    // Handle form changes using functional state update
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === "checkbox" ? checked : value;

        setFormData((prevData) => {
            const updatedData = { ...prevData, [name]: newValue };
            if (name === "password" || name === "repeatPassword") {
                setPasswordMatch(updatedData.password === updatedData.repeatPassword);
            }
            return updatedData;
        });

        if (name === "school") {
            const search = value.toLowerCase();
            const results = schoolsDK.filter((school) =>
                school.toLowerCase().includes(search)
            );
            setFilteredSchools(results);
            setShowDropdown(results.length > 0);
        }

        if (stud && name === "school" && value) {
            setErrors((prevErrors) => {
                const { studentID, ...rest } = prevErrors;
                return rest;
            });
        }

        if (errors[name]) {
            setErrors((prevErrors) => {
                const { [name]: removed, ...rest } = prevErrors;
                return rest;
            });
        }
    };

    const handleSelectSchool = (school) => {
        setFormData((prevData) => ({ ...prevData, school }));
        setShowDropdown(false);
    };

    const reqFields = ["email", "password", "acceptTerms", "firstname", "lastname"];

    // Inline auto-login functions
    const setUserData = async () => {
        try {
            const token = localStorage.getItem("sessionToken");
            if (!token) return;
            const response = await fetch(`${baseUrl}/User`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (response.ok) {
                setUserType(data);
            } else {
                console.error("Error setting user data:", data.error);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    const loginAccount = async ({ email, password }) => {
        try {
            const response = await fetch(`${baseUrl}/Auth`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email, password }),
            });

            let data;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                data = await response.json();
            } else {
                data = await response.text();
            }

            if (response.ok) {
                localStorage.setItem("sessionToken", data.sessionToken);
                await setUserData();
                return { ok: true };
            } else if (response.status === 400 && data === "Password reset required") {
                return { ok: false, resetPassword: true };
            } else {
                return { ok: false, error: "Wrong email or password" };
            }
        } catch (error) {
            console.error("Failed to login:", error);
            return { ok: false, error: "An error occurred while trying to login. Please try again." };
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = {};

        reqFields.forEach((field) => {
            if (!formData[field]) {
                const fieldPlaceholder =
                    inputFields.find((input) => input.name === field)?.placeholder || field;
                newErrors[field] = `Venligst udfyld ${fieldPlaceholder}`;
            }
            if (field === "password" && evaluatePasswordStrength(formData[field]) < 4) {
                newErrors[field] = "Adgangskoden er for svag";
            }
            if (field === "repeatPassword" && formData[field] !== formData.password) {
                newErrors[field] = "Adgangskoderne matcher ikke";
            }
            if (field === "email" && !formData[field].includes("@")) {
                newErrors[field] = "Indtast en gyldig e-mail";
            }
            if (field === "acceptTerms" && !formData.acceptTerms) {
                newErrors[field] = "Du skal acceptere vilkår og betingelser";
            }
        });

        if (stud) {
            formData.CVR = "0";
            if (!formData["school"]) {
                newErrors["school"] = "Skole er påkrævet";
            }
            if (!formData["studentID"]) {
                newErrors["studentID"] = "Studie-ID er påkrævet";
            }
        }

        if (formData.subscribeNewsletter && !formData.email) {
            newErrors["email"] = "E-mail er påkrævet";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }

        let cleanData = Object.fromEntries(
            Object.entries({ ...formData, userType: 0 }).filter(([_, value]) => value !== "")
        );
        let emailAccept = {
            email: formData.email,
            name: `${formData.firstname} ${formData.lastname}`,
            cookies: true,
            terms: true,
        };

        delete cleanData.repeatPassword;
        delete cleanData.acceptTerms;
        cleanData.userType = 0;
        cleanData.accountId = id;

        let response;
        if (plus) {
            response = await registerUnderAccount(cleanData);
        } else {
            response = await registerAccount(cleanData);
        }

        if (formData.subscribeNewsletter) {
            await emailSignup(emailAccept);
        }

        // Auto login after successful registration
        if (response.ok || response.accountId) {
            console.log("Account created successfully");
            const loginData = { email: formData.email, password: formData.password };
            const loginResponse = await loginAccount(loginData, setUserType);

            if (loginResponse.ok) {
                console.log("User logged in successfully");
                navigate("/"); // Navigate to dashboard or protected route
            } else if (loginResponse.resetPassword) {
                navigate("/reset-password");
            } else {
                console.error("Auto login failed, redirecting to login page");
                navigate("/login");
            }
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, submit: response.error }));
        }
    };

    const subscriptionHolder = [
        { value: "monthly", price: "XXX", label: "DKK/mnd." },
        { value: "yearly", price: "XXX", label: "DKK/anno. (15% rabat)" },
    ];

    const renderSubscription = (subscription) => (
        <label key={subscription.value}>
            <input
                type="radio"
                name="subscription"
                value={subscription.value}
                checked={formData.subscription === subscription.value}
                onChange={handleChange}
            />
            {subscription.price} {subscription.label}
        </label>
    );

    const renderPasswordStrength = (password) => (
        <div className={Styles.passwordStrength}>
            <p>Skal indeholde:</p>
            <ul>
                {password.length > 7 ? (
                    <li style={{ textDecoration: "line-through", color: "var(--Grey)" }}>
                        8 Tegn
                    </li>
                ) : (
                    <li>8 Tegn</li>
                )}
                {/[a-z]/.test(password) ? (
                    <li style={{ textDecoration: "line-through", color: "var(--Grey)" }}>
                        1 Lille Bogstav
                    </li>
                ) : (
                    <li>1 Lille Bogstav</li>
                )}
                {/[A-Z]/.test(password) ? (
                    <li style={{ textDecoration: "line-through", color: "var(--Grey)" }}>
                        1 Stor Bogstav
                    </li>
                ) : (
                    <li>1 Stor Bogstav</li>
                )}
                {/\d/.test(password) ? (
                    <li style={{ textDecoration: "line-through", color: "var(--Grey)" }}>
                        1 tal
                    </li>
                ) : (
                    <li>1 Tal</li>
                )}
            </ul>
        </div>
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, []);

    return (
        <form className={Styles.registerForm} onSubmit={handleSubmit}>
            <div className={Styles.leftColumn}>
                {inputFields.map((field) => (
                    <div
                        key={field.name}
                        className={Styles.formGroup}
                        ref={field.name === "school" ? dropdownRef : null}
                    >
                        {field.name === "password" &&
                            passwordFocused &&
                            renderPasswordStrength(formData.password)}
                        <p>{field.placeholder}</p>
                        <label htmlFor={field.name}>{field.label}</label>
                        {field.name === "school" ? (
                            <div className={Styles.dropdownContainer}>
                                <input
                                    type="text"
                                    id="school"
                                    name="school"
                                    placeholder="Søg efter en skole..."
                                    value={formData.school}
                                    onChange={handleChange}
                                    className={Styles.input}
                                />
                                {showDropdown && (
                                    <div className={Styles.dropdown}>
                                        {filteredSchools.map((school, index) => (
                                            <div
                                                key={index}
                                                className={Styles.dropdownItem}
                                                onClick={() => handleSelectSchool(school)}
                                            >
                                                {school}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <input
                                type={field.type}
                                id={field.name}
                                name={field.name}
                                placeholder={field.placeholder}
                                value={formData[field.name]}
                                onChange={handleChange}
                                onFocus={field.name === "password" ? handleFocus : undefined}
                                onBlur={field.name === "password" ? handleBlur : undefined}
                                className={
                                    `${field.name === "repeatPassword" && !passwordMatch ? Styles.inputError : ""} 
                   ${errors[field.name] ? Styles.inputError : ""}`
                                }
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className={Styles.rightColumn}>
                <div className={Styles.termsBox}>
                    <label>
                        <input
                            type="checkbox"
                            name="acceptTerms"
                            checked={formData.acceptTerms || false}
                            onChange={handleChange}
                        />
                        <span>
                            Accept af slutbruger licensen          
                            samt{" "}
                            <a style={{ color: "var(--Green)" }} href="/vilkaar" target="_blank" rel="noopener noreferrer">
                                termer og vilkår
                            </a>
                        </span>
                    </label>
                    {arkeng && (
                        <label>
                            <input
                                type="checkbox"
                                name="multUser"
                                checked={formData.multUser || false}
                                onChange={handleChange}
                            />
                            <span>
                                Opret link til oprettelse af flere brugere under samme konto
                            </span>
                        </label>
                    )}
                    <label>
                        <input
                            type="checkbox"
                            name="subscribeNewsletter"
                            checked={formData.subscribeNewsletter || false}
                            onChange={handleChange}
                        />
                        <span>
                            Tilmeld til nyhedsbrev og modtag opdateringer på markedets
                            banebrydende produktet
                        </span>
                    </label>
                </div>
                <div>
                    <button type="submit" className={Styles.submitButton}>
                        Opret bruger
                    </button>
                    {errors && (
                        <div className={Styles.errorBox}>
                            {Object.keys(errors).map((key) => (
                                <p key={key} style={{ width: "100%" }}>
                                    {errors[key]}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
}

export default RegisterForm;