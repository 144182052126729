export const helpText = {
  HelpPageContents: {
    EPD: {
      label: "EPD",
      subcategories: [
        { key: "EPD1" },
        { key: "EPD2" },
        { key: "EPD3" },
        { key: "EPD4" },
      ],
      content: {
        EPD1: {
          label: "Hvad er en EPD?",
          paragraphs: [
            {
              text: "EPD er en forkortelse for Environmental Product Declaration, på dansk kaldet en miljøvaredeklaration. En EPD dokumenterer byggevarens påvirkning på miljøet helt fra udvindingen af råmaterialerne, der bruges i produktionen til nedrivning, nedbrydning og eventuel genanvendelse. En EPD er ikke en certificering og vurderer altså ikke byggevaren, men derimod en deklaration der sikrer en ensartet måde at dokumentere miljøpåvirkningen for byggevarer.",
              reference:
                "EPD Danmark and BUILD (2021) Hvad er en miljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 25 November 2024).",
            },

            {
              title: "Branchespecifik EPD og specifik EPD",
              text: "Når man snakker om EPD’er, vil man ofte støde på ordene generiske data og specifikke EPD’er. Det skyldes, at der overordnet set findes tre forskellige typer EPD’er for byggevare. En branchespecifik EPD, en produktspecifik EPD og en projektspecifik EPD. De generiske data dækker oftest over en branchespecifik EPD og er ikke lige så præcise som de specifikke EPD’er - produktspecifik- og projektspecifik EPD.",
            },
            {
              title: "Den branchespecifikke EPD",
              text: "Den branchespecifikke EPD er baseret på et gennemsnit for branchen eller produkttypen og er derfor ikke lige så præcis som den produktspecifikke- og projektspecifikke EPD. De branchespecifikke EPD’er bliver ofte brugt til at udarbejde LCA på bygningsniveau og du vil kunne se eksempler på disse i et program som LCA Byg.",
            },
            {
              title: "En produktspecifik EPD",
              text: "En produktspecifik EPD er en EPD udarbejdet for et enkelt produkt, med miljøpåvirkninsresultater for lige præcis dette produkts livsforløb. Dog kan den produktspecifikke EPD indeholde flere produkter, hvis produktet for eksempel findes i flere farver eller med forskellige overfladebehandlinger.",
            },
            {
              title: "Den Projektspecifik EPD",
              text: "Den projektspecifikke EPD er en produktspecifik EPD med projektspecifikke data. Det vil sige at den projektspecifikke EPD, baseres på data fra en produktspecifik EPD. Hertil tillægges de projektspecifikke data, som for eksempel transportafstande og derved opnås en meget præcis EPD for produktet i lige netop dette projekt. ",
            },
          ],
          link: "https://www.sbst.dk/byggeri/alt-om-epder/artikler/tre-typer-af-epd%E2%80%99er-paa-byggevarer",
        },
        EPD2: {
          label: "Hvad finder du i en EPD?",
          paragraphs: [
            {
              text: "Du kan bruge en EPD når du skal foretage en livscyklusvurdering (LCA) af dit byggeri. En EPD kortlægger miljøpåvirkningerne for hele produktets livsforløb og består af faserne Produktion, Konstruktion, Brug, Bortskaffelse og Næste Produktsystem. Faserne er opdelt i moduler, der hver tager højde for forskellige aspekter, der måtte indgå i faserne.",
              image: {
                src: "/images/HelpPage/Faser-og-Moduler-01.png",
              },
              reference:
                " EPD Danmark and BUILD (2021) Hvad er en miljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 25 November 2024).",
            },
            {
              title: "Produktion (Modul A1-A3)",
              text: "Produktionsfasen med modulerne A1-A3 dækker over produktionen af produktet, herunder udvinding af råmateriale, transport til produktionssted og fremstilling af produktet. ",
            },
            {
              title: "Konstruktion (Modul A4-A5)",
              text: "Konstruktionsfasen rummer Modul A4, der dækker over transport af produktet fra produktionssted til byggeplads, samt Modul A5 der dækker over installation af produktet, herunder spild, strøm- og brændstofforbrug, samt emballageaffald.",
            },
            {
              title: "Brug (Modul B1-B7)",
              text: "Modulerne B1-B7 dækker over brugsfasen og rummer blandt andet vedligehold, reparation og udskiftning af produktet. For eksempel medregner modulet B4 udskiftning af produktet. Her medregnes produktion af et nyt produkt, samt affaldshåndtering af det gamle. ",
            },
            {
              title: "Bortskaffelse (Modul C1-C4)",
              text: "Bortskaffelsesfasen som rummer Modul C1-C4 dækker over miljøpåvirkningen ved endt brug af produktet. Herunder udledninger ved nedrivning, affaldsbehandling samt transport hertil og bortskaffelse. ",
            },
            {
              title: "Næste produktsystem (Modul D)",
              text: "Modul D er lidt særligt, da dette ligger udenfor systemgrænsen. Modul D rummer den potentielle gevinst, der vil være ved at genbruge, genanvende eller bruge produktet til genvinding. Modul D er altså en antagelse, da man ikke på forhånd kan være sikker på, hvordan produktet behandles efter endt brug.",
              reference:
                " EPD Danmark and BUILD (2021) Hvordan læses og bruges enmiljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 25 November 2024).",
            },
            {
              title: "Global Opvarmning (GWP)",
              tooltip: "/images/HelpPage/GWP-total-01.png",
              text: "GWP er en forkortelse for Global Warming Potential. GWP er det parametre der er mest oppe i tiden. GWP deklarerer udledningen af drivhusgasser for produktion, nedrivning og affaldshåndtering af et produkt. GWP dækker over flere forskellige gasser, men er omregnet til CO2-ækvivalenter for at de kan samles under ét parameter.",
              reference:
              " EPD Danmark and BUILD (2021) Hvordan læses og bruges enmiljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 25 November 2024).",
            },
            {
              
              text: "Udover at en EPD er inddelt i de fem forskellige faser, er den også inddelt i de forskellige miljøpåvirkningskategorier, der måtte være i sammenhæng med produktion, brug og bortskaffelse af byggevaren. Heri deklareres Miljøpåvirkninger, såsom global opvarmning og eutrofiering, Ressourceforbrug, som blandt andet dækker energi- og vandforbrug, Affaldskategorier og Biogent kulstof.",
              reference:
                "  EPD Danmark and BUILD (2021) Hvad er en miljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 25 November 2024).",
            },
          ],
        },
        EPD3: {
          label: "Hvordan læser du en EPD?",
          text: "Din EPD er inddelt i afsnit, for at du nemmere kan finde den information du skal bruge. Afsnittene i en EPD er:",
          list: [
            {
              title: "Formelle og obligatoriske oplysninger",
            },
            {
              title: "Produktinformation",
            },
            {
              title: "LCA Baggrund",
            },
            {
              title: "LCA Resultater",
            },
            {
              title: "Supplerende information ",
            },
            {
              title: "Referencer",
            },
          ],
          additionalParagraphs: [
            {
              text: "På forsiden og første side i din EPD finder du de Formelle- og Obligatoriske Oplysninger. Det er blandt andet informationer om byggevaren, EPD’ens gyldighed og de deklarerede faser.",
            },
            {
              text: "På EPD’ens anden side finder du Produktinformation. Her finder du informationer såsom sammensætning, tekniske egenskaber, indhold af farlige stoffer, samt produktlevetid.",
            },
            {
              text: "På den tredje side i din EPD, vil du finde LCA Baggrund. Dette afsnit indeholder informationer om baggrunden for livscyklusvurderingen der ligger til grund for EPD’en. Her kan du blandt andet se produktets egenskaber, produktionsdiagram, PCR, samt hvilke antagelser der er gjort i forbindelse med udarbejdelse af livscyklusvurderingen. ",
            },
            {
              text: "Efter LCA Baggrunden kommer LCA Resultater. Heri præsenteres resultaterne af livscyklusvurderingen for byggevaren. Resultaterne præsenteres i tabeller. I disse tabeller findes resultaterne for produktets miljøpåvirkninger, ressourceforbrug, affaldskategorier og output-strømme.",
            },
            {
              text: "Sidst i EPD’en findes den Supplerende Information. Dette afsnit kan for eksempel indeholde informationer om udledning til/påvirkning af indeluft, samt jord og vand. Derudover kan antagelser om bortskaffelse, levetid og transportafstande i nogle tilfælde findes herunder. ",
            },
            {
              text: "Slutteligt findes Referencerne, der lister LCA-udvikler, verifikatorer, anvendt software og database til EPD’ens LCA.",
              reference:
                " EPD Danmark and BUILD (2021) Hvad er en miljøvaredeklaration (EPD), og hvordan læses den? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 26 November 2024).",
            },
          ],
        },
        EPD4: {
          label: "Hvad skal du være opmærksom på?",
          paragraphs: [
            {
              title: "Forskellige standarder",
              tooltip: "/images/HelpPage/EPD-forside-01.png",
              text: "Det er vigtigt at være opmærksom på, hvilken standard din EPD er udarbejdet efter. Der findes hos EPD Danmark EPD’er udarbejdet efter to forskellige standarder, henholdsvis EN 15804+A1 (den gamle) og EN 15804+A2 (den nye). ",
            },
            {
              text: "Er EPD’erne ikke udarbejdet efter samme standard, skal man være opmærksom på at resultaterne ikke vil være sammenlignelige. ",
            },
            {
              text: "Du vil på forsiden og første side af EPD’en kunne finde information om hvilken standard den er udarbejdet efter. I EPD’er udarbejdet i henhold til EN 15804+A1 findes der tre tabeller under LCA resultater. Disse tre tabeller deklarerer Miljøpåvirkninger, Ressourceforbrug og Affaldskategorier. I en EPD udarbejdet i henhold til EN 15804+A2 vil der være fem tabeller under LCA resultater. Disse tabeller deklarerer Miljøpåvirkninger, Yderligere miljøpåvirkninger, Ressourceforbrug, Affaldskategorier og Biogent kulstof.",
              reference:
                "EPD Danmark and BUILD (2021) Hvordan læses og bruges enmiljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 26 November 2024).",
            },
            {
              title: "Gyldighed af EPD’en",
              tooltip: "/images/HelpPage/EPD-gyldighed-01.png",
              text: "En EPD er gyldig fem år efter udgivelse og skal herefter fornyes.Du skal altså være opmærksom på, om din EPD stadig er gyldig. Gyldighedsperioden kan du også finde på forsiden og på første side af din EPD. ",
              reference:
                " EPD Danmark and BUILD (2021) Hvordan læses og bruges enmiljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 25 November 2024).",
            },
            {title: "Resultater angivet i forskellige enheder"},
            {
              tooltip: "/images/HelpPage/EPD-side 1-01.png",
              text: "Skal du bruge din EPD til at sammenligne to eller flere byggevarer, skal du være opmærksom på, hvilken enhed der deklareres for i den enkelte EPD. Nogle producenter vælger at udarbejde deklarationen for en kvadratmeter af byggevaren, mens andre deklarerer for en kubikmeter eller for et kilogram. På den første side af din EPD kan du se hvilken enhed der deklareres for. ",
              reference:
                " EPD Danmark and BUILD (2021) Hvordan læses og bruges enmiljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 26 November 2024).",
            },
            {
              text: "Er dine deklarerede enheder forskellige, kan det være nødvendigt at omregne dem, for at de kan sammenlignes. På tredje side i din EPD vil du ofte kunne finde omregningsfaktoren til 1 kilogram.",
              tooltip: "/images/HelpPage/EPD-side 3-01.png",
            },
            {
              title: "Hvilke faser er medregnet?",
              tooltip: "/images/HelpPage/EPD-side-1- medregnede faser-01.png",
              text: "Når du læser en EPD, skal du også være opmærksom på, hvilke faser der medregnes i EPD’en. Nogle EPD’er inkluderer alle faser mens andre for eksempel udelader konstruktions- og brugsfasen, samt modul D. På første side af din EPD finder du EPD typen og herunder hvilke faser der medregnes.",
              reference:
                "EPD Danmark and BUILD (2021) Hvordan læses og bruges enmiljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 25 November 2024).",
            },
            {
              text: "Du kan i Bygningsreglementet få et overblik over hvilke faser du skal bruge for at opfylde en LCA for dit byggeri. Bliv klogere på LCA-kravene ved at klikke her: ",
              link: "https://www.bygningsreglementet.dk/tekniske-bestemmelser/11/brv/version-2-bygningers-klimapaavirkning/kap-1_2/",
            },
            {
              title: "Levetid",
              tooltip: "/images/HelpPage/EPD-side 2-01.png",
              text: "Når du bruger din EPD til en livscyklusvurdering af en bygning eller bygningsdel, skal du huske at angive levetiden for byggevaren. Det er dog ikke altid at en EPD deklarerer levetiden på produktet. For en EPD udarbejdet i henhold til EN 15804+A1 er der nemlig kun krav til at levetiden deklareres, hvis EPD’en medtager alle faser, fra vugge til grav. Er EPD’en udarbejdet i henhold til EN 15804+A2 er der krav om levetid, hvis der defineres en funktionel enhed, hvilket vil sige at modulerne A4, A5 og B1-B7 inddrages.",
              reference:
                " EPD Danmark and BUILD (2021) Hvordan læses og bruges enmiljøvaredeklaration (EPD)? [PDF]. Available at: https://www.epddanmark.dk/dokumenter/ (Accessed: 26 November 2024).",
            },
            {
              text: "Vil du vide mere om EPD’er og hvordan du læser dem, kan du finde fire udførlige guides på EPD Danmarks hjemmeside.",
              link: "https://www.epddanmark.dk/dokumenter/",
            },
          ],
        },
      },
    },
    // Tekniske: {
    //   label: "Tekniske specifikationer",
    //   subcategories: [
    //     { key: "Tekniske1" },
    //     { key: "Tekniske2" },
    //     { key: "Tekniske4" },
    //     { key: "Tekniske5" },
    //     { key: "Tekniske6" },
    //     { key: "Tekniske7" },
    //   ],
    //   content: {
    //     Tekniske1: {
    //       label: "Brandklasser",
    //       paragraphs: [
    //         {
    //           text: "I en byggevares ydeevnedeklaration (DoP) vil du finde varens brandklassifikation, eller reaktion på brand. I Danmark bruger vi Euroklasse systemet til at klassificere byggevares brandegenskaber. Euroklasse systemet inddeler produkterne i syv forskellige klasser der går fra A1 til F, hvor A1 er den højeste og F den laveste klassificering.",
    //           image: {
    //             src: "/images/HelpPage/brandklasser1.svg",
    //             minWidth: "900px",
    //           },
    //         },
    //         {
    //           text: "Udover de syv klasser, tildeles byggevarer også yderligere tillægsklassificeringer, der beskriver henholdsvis røgudvikling ved brand, samt brændende dråber og partikler. Klasse A1 og F kombineres ikke med disse tillægsklasser. Røgudviklingen inddeles i tre klasser fra s1 med meget begrænset mængde røgudvikling, til s3 hvor der ingen krav er til røgudviklingen. Risiko for brændende dråber og partikler ved brand inddeles ligeledes i tre klasser, fra d0 som er lig med ingen brændende dråber, til d2 hvor der udvikles en del brændende dråber ved brand.",
    //           reference:
    //             "Kingspan (2022) What is the Euroclass system? [Online]. Available at: https://www.kingspan.com/gb/en/knowledge-articles/what-is-the-euroclass-system/ (Accessed: 27 November 2024).",
    //         },
    //         {
    //           text: "I Bygningsreglementet stilles der krav til, hvilken brandklasse dine konstruktioner skal være alt efter type, størrelse og brug af din bygning. På Bygningsreglementets hjemmeside kan du finde Bilag til Brand, der guider dig igennem hvilke materialer du kan bruge i de specifikke konstruktioner. ",
    //         },
    //         {
    //           text: "Du kan finde Bilagene her:",
    //           link: "https://www.bygningsreglementet.dk/tekniske-bestemmelser/05/vejledninger/",
    //         },
    //         {
    //           title: "Sådan læser du produktets brandklassificering",
    //           text: "På nedenstående illustration er et eksempel på hvordan en brandklassificering kunne se ud. Den første halvdel, REI 60, relaterer sig til klassificering for en konstruktion og er altså ikke noget du typisk vil finde i en byggevares DoP.",
    //           image: {
    //             src: "/images/HelpPage/brandklasser2.svg",
    //             maxWidth: "420px",
    //           },
    //         },
    //         {
    //           text: "R’et står for Bærevne, E’et for Integritet og I’et for Isolation. REI betyder at det er en bærende konstruktion og 60 fortæller at den holder sin bæreevne, integritet og isolans i 60 minutter. Havde det været en ikke bærende konstruktion ville der have stået EI i stedet.",
    //         },
    //         {
    //           text: "Den sidste halvdel, A2-s1,d0, er det format, du typisk vil finde under brandklassificering i din DoP. Som tidligere nævnt beskriver A2 byggevarens overordnede brandklasse og i hvilken grad produktet bidrager til brand, som her er yderst begrænset bidrag til brand, idet produktet er ubrændbart. Røgudviklingen er beskrevet ved tillægsklassen s1, som er lig med meget begrænset røgudvikling. Til slut har vi d0, der informerer om risikoen for brændende dråber og partikler. Her er det igen den bedst mulige klassificering, hvilket vil sige at der ikke er risiko for brændende dråber og partikler fra produktet.",
    //           reference:
    //             " Rockwool (no date) Markedets Mest Brandsikre Isoleringsmateriale, Rockwool.com [Online]. Available at: https://www.rockwool.com/dk/raadgivning-og-inspiration/brandsikkerhed/isoleringsmaterialer-og-brand/ (Accessed: 27 November 2024). ",
    //         },
    //       ],
    //     },
    //     Tekniske2: {
    //       label: "Densitet",
    //       text: "Densiteten, eller massefylden som den også kaldes, af et materiale beskriver dets masse i forhold til dets rumfang. ",
    //     },
    //     Tekniske4: {
    //       label: "Trykstyrke",
    //       paragraphs: [
    //         {
    //           text: "Når du læser en ydeevnedeklaration, vil du kunne støde på en angivet trykstyrke. Trykstyrken for et materiale angiver den maksimale trykspænding, et materiale kan bære uden at materialet bryder sammen.",
    //           reference:
    //             " Buchwald, V.F. (2024) Trykstyrke, Danmarks Nationalleksikon. Available at: https://lex.dk/trykstyrke (Accessed: 19 December 2024).",
    //         },
    //         {
    //           text: "Styrkeforholdene i et materiale afhænger typisk af materialets densitet. Jo større densitet, desto større styrke. Det vil altså sige at byggematerialer af samme materialer, men med forskellig densitet,vil have forskellige styrker.",
    //           reference:
    //             " Mollerup, J. (1991) Husbygnings materialer. 5. Haslev: Nyt Nordisk forlag. ",
    //         },
    //         {
    //           text: "Du vil som regel finde angivne trykstyrker i ydeevnedeklarationer for mursten. Her er ofte angivet både en normaliseret trykstyrke og middeltrykstyrke. Skal man udføre beregninger for sit murværk, skal man være opmærksom på at bruge den normaliserede trykstyrke, der er middelstyrken af en sten omregnet til trykstyrken for en byggesten med en bredde på 100 mm og højde på 100 mm.",
    //           reference:
    //             " Teknologisk Institut (no date) Trykstyrke, Mur & Tag. Available at: https://www.mur-tag.dk/materialer/byggesten-mursten-og-blokke/egenskaber-byggesten/trykstyrke/ (Accessed: 19 December 2024).",
    //           image: "",
    //         },
    //       ],
    //     },
    //     Tekniske5: {
    //       label: "Termisk modstand",
    //       paragraphs: [
    //         {
    //           text: "Den termiske modstand, også kaldet isolans, beskriver et materialelags evne til at isolere. Jo større isolansen er, desto bedre isolere materialelaget. Isolansen kan bestemmes ud fra et materiales varmeledningsevne og tykkelsen af materialelaget. Divideres tykkelsen med varmeledningsevnen, resulterer det i materialelagets isolans.",
    //         },
    //         {
    //           text: "Når isolansen af en hel konstruktion skal bestemmes, adderes isolansen for hvert materialelag. Hertil skal man huske også at lægge overgangsisolansen til. Overgangsisolansen er en isolans, der opstår på yder- og inderside af væggen, grundet luftlaget tæt på væggen. Værdierne for overgangsisolans er tabelværdier",
    //         },
    //         {
    //           text: "Isolansen af en konstruktion kan du bruge til at bestemme U-værdien for konstruktionen. Da U-værdien er den reciprokke værdi af isolansen, skal man dividere 1 med isolansen for at bestemme U-værdien.",
    //           reference:
    //             " Iversen, H.L. et al. (no date) Fysik F-C, Praxis [Online]. Available at: https://fysikc.praxis.dk/2892 (Accessed: 27 November 2024).",
    //         },
    //       ],
    //     },
    //     Tekniske6: {
    //       label: "Varmeledningsevne",
    //       paragraphs: [
    //         {
    //           text: "Et materiales varmeledningsevne beskriver dets evne til at lede varme. Varmeledningsevnen kaldes ofte for en lambda værdi (𝜆-værdi). Jo højere den specifikke varmeledningsevne er for et materiale, desto bedre leder det varmen. Når vi kigger på isoleringsmaterialer, vil vi altså have et materiale med så lav varmeledningsevne som muligt. En typisk varmeledningsevne for isoleringsmaterialer ligger mellem 0,04 W/m*K og 0,035 W/m*K.",
    //           reference:
    //             " Iversen, H.L. et al. (no date) Fysik F-C, Praxis [Online]. Available at: https://fysikc.praxis.dk/2892 (Accessed: 27 November 2024).",
    //         },
    //       ],
    //     },
    //     Tekniske7: {
    //       label: "Biologisk Holdbarhed",
    //       paragraphs: [
    //         {
    //           text: "I Danmark er det, grundet den høje luftfugtighed, særligt nødvendigt at tage højde for træets biologiske holdbarhed, når det anvendes til udendørs formål.",
    //           reference:
    //             " Beck, S.A. (2015) Holdbarhed - Valg af Træ, Træ.dk [Online]. Available at: https://www.trae.dk/leksikon/holdbarhed-valg-af-trae/ (Accessed: 19 December 2024).",
    //         },
    //         {
    //           text: "Da træ er et biologisk materiale, vil det nedbrydes naturligt. Den naturlige nedbrydning af træet skyldes hovedsageligt de to grupper af organismer, insekter og svampe. I det danske klima er særligt fokus på svampeangreb vigtigt. Lige så snart træet er fugtigt, vil det være udsat for svampeangreb.",
    //           reference:
    //             " Bergstedt, A.E. (2023) Træet længe Leve Afsnit 1: Træets Naturlige Holdbarhed, Skovdyrkerne [Online]. Available at: https://skovdyrkerne.dk/medlemsblade/skovdyrkeren-49/traeet-laenge-leve-afsnit-1-traeets-naturlige-holdbarhed/ (Accessed: 19 December 2024).",
    //         },
    //         {
    //           text: "For at tydeliggøre de forskellige træsorters holdbarhed for forbrugeren og informere valget af træ til et givent projekt, vil træets holdbarhed ofte være angivet i trævarens ydeevnedeklaration.",
    //         },
    //         {
    //           text: "For træs biologiske holdbarhed findes tre forskellige standarder: DS/EN 335, DS/EN 350 og DS/EN 460. Vi vil kort kigge på de to standarder du oftest vil støde på, nemlig DS/EN 335 og DS/EN 350.",
    //         },
    //         {
    //           text: "DS/EN 335 indikerer brugsklassen for træet i forhold til dets naturlige nedbrydning. I DS/EN 335 findes fem brugsklasser, hvor Brugsklasse 1 er den mildeste. Ved Brugsklasse 1 er træet placeret over jorden og udsættes ikke for fugt. Ved Brugsklasse 5, som er den hårdeste, er træet udsat for en vedvarende placering i saltvand. Træets brugsklasse indikerer altså hvordan det anbefales at trækvaliteten bruges.",
    //           reference:
    //             " Teknologisk Institut (2020) Holdbarhed: Standarder for træ, Træ.dk [Online]. Available at: https://www.trae.dk/leksikon/holdbarhed-standarder-for-trae/ (Accessed: 19 December 2024).",
    //           image: "",
    //         },
    //         {
    //           text: "En anden standard og den der oftest vil fremgå af en trævarers ydeevnedeklaration er DS/EN 350. Med DS/EN 350 beskrives den naturlige, eller biologiske, holdbarhed af massivt træ. I DS/EN 350 er ligeledes fem Holdbarhedsklasser. Her er Holdbarhedsklasse 1 den bedste indikerer et Meget Holdbart træ. Hvor Holdbarhedsklasse 5 indikerer at træet ikke er holdbart.",
    //           reference:
    //             " Teknologisk Institut (2020) Holdbarhed: Standarder for træ, Træ.dk [Online]. Available at: https://www.trae.dk/leksikon/holdbarhed-standarder-for-trae/ (Accessed: 19 December 2024).",
    //           image: "",
    //         },
    //         {
    //           text: "Generelt gælder det for træ at en høj densitet og en mørkere træart giver en længere holdbarhed. Ligeledes vil træets kerneved (det inderste af træet) være mere holdbart end dets splintved (det yderste af træet).",
    //           reference:
    //             "Beck, S.A. (2015) Holdbarhed - Valg af Træ, Træ.dk [Online]. Available at: https://www.trae.dk/leksikon/holdbarhed-valg-af-trae/ (Accessed: 19 December 2024).",
    //         },
    //       ],
    //     },
    //   },
    // },
    // Certificeringer: {
    //   label: "Certificeringer",
    //   subcategories: [
    //     { key: "Certificeringer1" },
    //     { key: "Certificeringer2" },
    //     { key: "Certificeringer3" },
    //     {key: "Certificeringer4" }

    //   ],
    //   content: {
    //     Certificeringer1: {
    //       label: "FSC",
    //       image: "",
    //       paragraphs: [
    //         {
    //           text: "FSC står for Forest Stewardship Council og er en global certificering der indikerer ansvarlig og bæredygtig skovdrift. FSC Certificeringen søger at værne om biodiversitet, vandmiljø, oprindelige folk og skovarbejdere globalt, samt at udbrede demokrati og uddannelse lokalt. Derudover søger FSC certificeringen at forhindre afskovning og bevare skovarealet.",
    //         },
    //         {
    //           text: "FSC opstod i 1994 ovenpå frustrationer over den hastige skovrydning, der fandt sted gennem 1980’erne og 1990’erne. I et forsøg på at finde fælles fodslag og bremse den hurtige rydning af verdens skove, fandt en række miljøorganisationer og virksomheder sammen og startede FSC. I dag er FSC i 89 lande.",
    //           reference:
    //             " FSC (no date) Intro til FSC, FSC [Online]. Available at: https://www.dk.fsc.org/dk-da/intro-til-fsc (Accessed: 28 November 2024).",
    //         },
    //       ],
    //       list: [
    //         {
    //           title: "Overholdelse af lovgivning",
    //           text: "Skovejeren skal overholde alle gældende love, forordninger og nationalt ratificerede internationale traktater, konventioner og aftaler.",
    //         },
    //         {
    //           title: "Arbejdstagerens ansættelse- og arbejdsvilkår",
    //           text: "Skovejeren skal opretholde og/eller forbedre arbejdstagernes sociale og økonomiske velfærd.",
    //         },
    //         {
    //           title: "Oprindelige folks rettigheder",
    //           text: "Skovejeren skal identificere og respektere oprindelige folks juridiske og hævdvundne rettigheder til at besidde, anvende og forvalte jord, territorier og ressourcer, der berøres af driftsaktiviteter.",
    //         },
    //         {
    //           title: "Forholdet til lokalsamfundet",
    //           text: "Skovejeren skal bidrage til at fastholde eller styrke lokalsamfundenes sociale og økonomiske velfærd.",
    //         },
    //         {
    //           title: "Skovens goder",
    //           text: "Skovejeren skal forvalte skovejendommens mange produkter og tjenester på en effektiv måde for at sikre eller styrke den økonomiske bæredygtighed og de miljømæssige og sociale fordele.",
    //         },
    //         {
    //           title: "Miljøværdier og påvirkning",
    //           text: "Skovejeren skal vedligeholde, bevare og/eller genoprette skovejendommens økosystemtjenester og miljøværdier og undgå, genoprette eller afbøde negative miljøpåvirkninger.",
    //         },
    //         {
    //           title: "Driftsplanlægning",
    //           text: "Skovejeren skal have en driftsplan, der afspejler skovejerens politikker og målsætninger. Planen skal stå i forhold til driftens omfang og intensitet samt risikoen forbundet hermed. Driftsplanen skal holdes opdateret med information fra overvågningen, således at der sikres en fleksibel drift, som løbende kan tilpasses de faktiske forhold. Dokumentation omkring planlægning og procedurer skal være tilstrækkelig til at kunne instruere personale, informere berørte interessenter og begrunde driftsmæssige beslutninger.",
    //         },
    //         {
    //           title: "Overvågning og vurdering",
    //           text: "Skovejeren skal kunne dokumentere, at overvågning og evaluering i forhold til at nå driftsmålene finder sted, samt konsekvenser af driften. Indsatsen skal stå i forhold til driftens omfang og intensitet samt risici ved driften. Formålet er at implementere en fleksibel drift.",
    //         },
    //         {
    //           title: "Høje bevaringsværdier",
    //           text: "Skovejeren skal bevare og/eller fremme høje bevaringsværdier inden for skovejendommen ved at følge forsigtighedsprincippet.",
    //         },
    //         {
    //           title: "Gennemførelse af driften",
    //           text: "Driftsaktiviteter, der udføres af eller for skovejeren på skovejendommen, skal vælges og gennemføres i henhold til skovejerens økonomiske, miljømæssige og sociale politikker og målsætninger og i overensstemmelse med FSC’s principper og kriterier.",
    //         },
    //       ],
    //       additionalParagraphs: [
    //         {
    //           text: "De ovenstående grundprincipper er direkte gengivet fra FSC’s hjemmeside. Du kan finde de 10 grundprincipper og meget mere information om FSC her.",
    //           link: "https://www.dk.fsc.org/dk-da/intro-til-fsc/hvad-er-fsc",
    //         },
    //       ],
    //     },
    //     Certificeringer2: {
    //       label: "PEFC",
    //       paragraphs: [
    //         {
    //           text: "PEFC, Programme for the Endorsement of Forest Certification, er en international organisation, der søger at fremme bæredygtigt skovbrug i hele verden. PEFC beskriver bæredygtig skovforvaltning ved skovbrug, hvor driften af skoven skal være miljømæssig forsvarlig, socialt gavnlig og økonomisk levedygtig for såvel nuværende som for fremtidige generationer.",
    //         },
    //         {
    //           text: "PEFC blev stiftet i 1999 og er i dag at finde i 55 lande. PEFC er en paraplyorganisation med nationale selvstændige medlemmer. De selvstændige nationale organisationer står for at implementere det internationale PEFC-systemet og tilpasse det til forholdene i netop deres område. Det vil sige at det i Danmark er PEFC Danmark der sørger for at tilpasse PEFC-systemet til de danske forhold.",
    //           reference:
    //             " PEFC (no date b) PEFC Organisationen, PEFC Danmark [Online]. Available at: https://www.pefc.dk/om-pefc/pefc-organisationen (Accessed: 28 November 2024).",
    //         },
    //         {
    //           text: "PEFC certificeringen anvender internationale ISO standarder til certificering og sikrer bæredygtig skovdrift ved at have fokus på følgende punkter:",
    //         },
    //       ],
    //       list: [
    //         { title: "Opretholdelse eller forøgelse af biodiversiteten" },
    //         { title: "Beskyttelse biologisk vigtig skovområde" },
    //         { title: "Forbud mod konvertering af skov" },
    //         {
    //           title:
    //             "Forbud mod anvendelse af de mest farlige kemikalier og GMO'er",
    //         },
    //         {
    //           title:
    //             "Beskyttelse af arbejdstagernes rettigheder og velfærd, og fremme af den lokale beskæftigelse",
    //         },
    //         {
    //           title:
    //             "Anerkender princippet om frie, forudgående og informeret samtykke (FPIC), FN-erklæringen om oprindelige folks rettigheder, og ILO-konvention 169 om oprindelige folk og stammefolk",
    //         },
    //         {
    //           title:
    //             "Respekt for ejendoms- og brugsrettigheder såvel som sædvanlige og traditionelle rettigheder",
    //         },
    //         {
    //           title:
    //             "Bestemmelser om samråd med lokalbefolkningen og interessenter",
    //         },
    //         { title: "Overholdelse af gældende lovgivning" },
    //         {
    //           title:
    //             "Kræver virksomheder at påvise overensstemmelse med social-, sundheds- og sikkerhedskrav i sporbarhedscertificering",
    //         },
    //       ],
    //       additionalParagraphs: [
    //         {
    //           text: "Vil du vide mere om PEFC organisationen og certificeringen, kan du finde det her:",
    //           link: "https://www.pefc.dk/om-pefc/hvad-vi-gor/hvad-gor-pefc-unik",
    //         },
    //       ],
    //     },
    //     Certificeringer3: {
    //       label: "Den Blå Krans",
    //       paragraphs: [
    //         {
    //           text: "Den Blå Krans symboliserer to mærker med et fælles logo. Den Blå Krans er både Asthma Allergy Nordic og Astma-Allergi Danmarks allergimærkning. ",
    //         },
    //         {
    //           text: "Astma-Allergi Danmark er Danmarks egen allergimærkning, mens Asthma Allergy Nordic er en fælles allergimærkning for de tre Nordiske lande, Norge, Sverige og Danmark. De tre allegimærkninger blev forenet i 2018, for at danne et stærkt internationalt mærke, samt forene den viden og erfaring, som de tre mærkningsordninger har hver især. Fælles for Asthma Allergy Nordic og Astma-Allergi Danmarks allergimærkning er Den Blå Krans der pryder begge logoer.",
    //         },
    //         {
    //           text: "Produkter med Den Blå Krans er særligt allergivenlige produkter, hvor eksperter har gennemgået samtlige ingredienser og deres oprindelse. De eksperter der tjekker produkter med Den Blå Krans, er fageksperter inden for blandt andet toksikologi, sundhed, kemi, biologi og hudallergi.",
    //           reference:
    //             " Astma-Allergi Danmark (no date a) Det er let at Træffe et allergivenligt valg, astma-allergi.dk. [Online] Available at: https://www.astma-allergi.dk/allergimaerkning/ (Accessed: 28 November 2024). ",
    //         },
    //         {
    //           text: "Den Blå Krans symboliserer produkter uden indhold af de mest hyppige allergifremkaldende stoffer. Nogle af de stoffer der ikke tillades i produkter med Den Blå Krans er blandt andet:",
    //         },
    //       ],
    //       list: [
    //         { title: "Syntetisk og naturlig parfume" },
    //         { title: "Æteriske olier" },
    //         {
    //           title:
    //             "Tilsætning af formaldehyd og stoffer, der kan frigive formaldehyd",
    //         },
    //         { title: "Konserveringsmidlerne kathon, MI og MCI" },
    //         {
    //           title:
    //             "Fødevareallergener, som EU har defineret som mærkningspligtige",
    //         },
    //       ],
    //       additionalParagraphs: [
    //         {
    //           text: "Er du nysgerrig på  Astma-Allergi Danmarks allergimærkning kan du læse mere her:",
    //           link: "https://www.allergimaerket.dk/",
    //         },
    //       ],

    //     },
    //   },
    // },
  },
};
