import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import styles from "./filter.module.css";
import { certificationMap, debounce } from "../../../components/Utils/Helpers";
import FilterCategory from "./cmp/filterCategory";
import Slider from "../../../components/Slider.jsx";
import Checkbox from "../../../components/Checkbox.jsx";
import { shortToLongUnit } from "../../../components/Utils/Helpers";
const DEFAULT_FILTERS = {
  compliances: [],
  producers: [],
  subCategories: [],
  brandValue: [],
  validTo: null,
  certifications: [],
  safetyLevelMax: null,
  countryCodes: [], // Changed from country to countryCodes
  gwpValues: {
    a1A3: null,
    a5: null,
    b3: null,
    c1: null,
    c2: null,
    c3: null,
    d: null,
  },
  unit: "kg",
};

const parseGWPValues = (gwpRaw) => {
  // Expecting keys like "a1A3", "a1A3Min", "a1A3Max", etc.
  const gwpValues = {};
  Object.entries(gwpRaw).forEach(([key, value]) => {
    gwpValues[key] = value !== null ? parseFloat(value) : null;
  });
  return gwpValues;
};

const parseSearchParams = (searchParams) => {
  const getArray = (key) => {
    const arr = searchParams.getAll(key);
    return arr.length ? arr : [];
  };

  const gwpRaw = {
    a1A3: searchParams.get("a1A3"),
    a5: searchParams.get("a5"),
    b3: searchParams.get("b3"),
    c1: searchParams.get("c1"),
    c2: searchParams.get("c2"),
    c3: searchParams.get("c3"),
    c4: searchParams.get("c4"),
    d: searchParams.get("d"),
  };

  return {
    compliances: getArray("compliances"),
    producers: getArray("producers"),
    brandValue: getArray("brandKlasseUnits"),
    subCategories: getArray("subCategories"),
    validTo: searchParams.get("validTo")
      ? parseInt(searchParams.get("validTo"), 10)
      : null,
    certifications: getArray("certifications"),
    safetyLevelMax: searchParams.get("safetyLevelMax")
      ? parseInt(searchParams.get("safetyLevelMax"), 10)
      : null,
    gwpValues: parseGWPValues(gwpRaw),
    countryCodes: getArray("countryCodes"), // Use countryCodes
    unit: "kg",
  };
};

function FilterQuery({ setQuery, data, pageN, setPageN, pageS, setProducts, setProductLoad }) {
  const [activeFilterCategory, setActiveFilterCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [maxHeight, setMaxHeight] = useState({
    GWP: "0",
    Standard: "0",
    Producent: "0",
    subCategories: "0",
    "Gyldig til": "0",
    "Safety Level": "0",
    Brandklasse: "0",
    Certifikationer: "0",
    Land: "0", 
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  // Build initial filters from URL or default
  const initialFilters = useMemo(() => {
    if (data && Object.keys(data).length > 0) {
      const params = new URLSearchParams(location.search);
      return parseSearchParams(params);
    }
    return DEFAULT_FILTERS;
  }, [location.search, data]);

  const [filters, setFilters] = useState(initialFilters);
  const [newFilters, setNewFilters] = useState(initialFilters);

  // Update filters based on URL parameters
  const updateFilters = (paramsOrQuery) => {
    const params =
      paramsOrQuery instanceof URLSearchParams
        ? paramsOrQuery
        : new URLSearchParams(Object.entries(paramsOrQuery));
    const updated = parseSearchParams(params);
    setFilters(updated);
    setNewFilters(updated);
  };

  useEffect(() => {
    updateFilters(new URLSearchParams(location.search));
  }, [location.search]);

  // Update URL parameters based on current filters
  const updateURLParams = (filtersToUpdate) => {
    const params = new URLSearchParams();
    const appendArray = (key, arr) =>
      arr.forEach((val) => params.append(key, val));

    if (filtersToUpdate.compliances.length)
      appendArray("compliances", filtersToUpdate.compliances);
    if (filtersToUpdate.producers.length)
      appendArray("producers", filtersToUpdate.producers);
    if (filtersToUpdate.brandValue.length)
      appendArray("brandKlasseUnits", filtersToUpdate.brandValue);
    if (filtersToUpdate.subCategories.length)
      appendArray("subCategories", filtersToUpdate.subCategories);
    if (filtersToUpdate.certifications.length)
      appendArray("certifications", filtersToUpdate.certifications);
    if (filtersToUpdate.validTo) params.set("validTo", filtersToUpdate.validTo);
    if (filtersToUpdate.safetyLevelMax)
      params.set("safetyLevelMax", filtersToUpdate.safetyLevelMax);
    Object.entries(filtersToUpdate.gwpValues).forEach(([key, value]) => {
      if (value !== null) params.set(key, value);
    });
    // Use countryCodes instead of country
    if (filtersToUpdate.countryCodes && filtersToUpdate.countryCodes.length) {
      appendArray("countryCodes", filtersToUpdate.countryCodes);
    }

    navigate(`${location.pathname}?${params.toString()}`);
  };

  const resetFilters = () => {
    setProductLoad(true);
    setProducts([]);
    setActiveFilterCategory(null);
    setFilters(DEFAULT_FILTERS);
    setNewFilters(DEFAULT_FILTERS);
    setPageN(1);
    navigate(location.pathname, { replace: true });
  };

  const handleSearch = () => {
    setPageN(1);
    setFilters(newFilters);
    updateURLParams(newFilters);
  };

  const debouncedSetQuery = useCallback(
    debounce((f) => {
      if (data && Object.keys(data).length > 0) {
        setQuery({
          pageSize: pageS || 10,
          pageNumber: pageN || 1,
          compliances: f.compliances,
          producers: f.producers,
          subCategories: f.subCategories,
          brandKlasseUnits: f.brandValue,
          certifications: f.certifications,
          validTo: f.validTo,
          strings: [],
          countryCodes: f.countryCodes, // Use countryCodes
          safetyLevelMax: f.safetyLevelMax,
          unit: data.unit,
          ...f.gwpValues,
        });
      }
    }, 1),
    [data, pageN, pageS]
  );

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      debouncedSetQuery(filters);
    }
  }, [filters, data, debouncedSetQuery]);

  const toggleCategory = (category) => {
    const newHeight = activeFilterCategory === category ? "0" : "2000px";
    setActiveFilterCategory((prev) => (prev === category ? null : category));
    setMaxHeight((prev) => ({ ...prev, [category]: newHeight }));
  };

  // === Renderers for filter sections ===

const renderGWPFilter = () => {
  if (!data?.gwpValues) return null;
  const minMax = {};

  // Build min/max for each subcategory
  Object.entries(data.gwpValues).forEach(([key, value]) => {
    if (value == null) return;
    const baseKey = key.replace(/Min|Max$/, "");
    if (!minMax[baseKey]) minMax[baseKey] = { min: null, max: null };
    if (key.endsWith("Min")) minMax[baseKey].min = value;
    else if (key.endsWith("Max")) minMax[baseKey].max = value;
  });

  // Ensure min is not greater than max
  Object.values(minMax).forEach((range) => {
    if (range.min > range.max) [range.min, range.max] = [range.max, range.min];
  });

  const onSliderChange = (subCat, newVal) => {
    const { min, max } = minMax[subCat];
    const clamped = Math.min(Math.max(parseFloat(newVal), min), max);
    setNewFilters((prev) => ({
      ...prev,
      gwpValues: { ...prev.gwpValues, [subCat]: clamped },
    }));
  };

  return Object.entries(minMax).map(([subCat, { min, max }]) => {
    const isEqual = min === max;
    const adjustedMin = isEqual ? min - 1 : min;
    const adjustedMax = isEqual ? max + 1 : max;
    
    return (
      <div key={subCat} style={{ maxHeight: maxHeight.GWP }}>
        <Slider
          useScientific
          label={subCat.toUpperCase()}
          value={newFilters.gwpValues[subCat] ?? adjustedMax}
          onChange={(val) => onSliderChange(subCat, val)}
          min={adjustedMin}
          max={adjustedMax}
          step={0.01}
          number={newFilters.gwpValues[subCat]}
          initialValue={max}
        />
      </div>
    );
  });
};

 

  // Reusable checkbox group renderer
  const renderCheckboxGroup = (
    items,
    selected,
    onChange,
    maxHeightKey,
    placeholder = null
  ) => (
    <div style={{ maxHeight: maxHeight[maxHeightKey] }}>
      {placeholder && (
        <input
          type="search"
          placeholder={placeholder}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginBottom: "10px", width: "100%", padding: "5px" }}
        />
      )}
      {items.map((item) => (
        <div key={item} className={styles.producercheckbox}>
          <Checkbox
            name={item}
            checked={selected.includes(item)}
            onChange={() => onChange(item)}
            label={item}
          />
        </div>
      ))}
    </div>
  );

  const renderComplianceFilter = () => {
    if (!data?.compliances) return null;
    const items = Array.from(new Set(data.compliances));
    const handleChange = (item) =>
      setNewFilters((prev) => ({
        ...prev,
        compliances: prev.compliances.includes(item)
          ? prev.compliances.filter((c) => c !== item)
          : [...prev.compliances, item],
      }));
    return renderCheckboxGroup(items, newFilters.compliances, handleChange, "Standard");
  };
  
  const renderMaterialsFilter = () => {
  if (!data?.subCategories) return null;
  const items = Array.from(new Set(data.subCategories));
  const handleChange = (item) =>
    setNewFilters((prev) => ({
      ...prev,
      subCategories: prev.subCategories.includes(item)
        ? prev.subCategories.filter((m) => m !== item)
        : [...prev.subCategories, item],
    }));
  return renderCheckboxGroup(items, newFilters.subCategories || [], handleChange, "Materialer");
};

  const renderProducerFilter = () => {
    if (!data?.producers) return null;
    const items = Array.from(new Set(data.producers))
      .filter((p) => p.toLowerCase().includes(searchQuery.toLowerCase()))
      .sort((a, b) => a.localeCompare(b));
    const handleChange = (item) =>
      setNewFilters((prev) => ({
        ...prev,
        producers: prev.producers.includes(item)
          ? prev.producers.filter((p) => p !== item)
          : [...prev.producers, item],
      }));
    return renderCheckboxGroup(
      items,
      newFilters.producers,
      handleChange,
      "Producent",
      "Søg producent"
    );
  };

  const renderBrandFilter = () => {
    if (!data?.brandValue) return null;
    const items = Array.from(new Set(data.brandValue));
    const handleChange = (item) =>
      setNewFilters((prev) => ({
        ...prev,
        brandValue: prev.brandValue.includes(item)
          ? prev.brandValue.filter((b) => b !== item)
          : [...prev.brandValue, item],
      }));
    return renderCheckboxGroup(items, newFilters.brandValue, handleChange, "Brandklasse");
  };

  const renderValidToFilter = () => {
    const currentYear = new Date().getFullYear();
    return (
      <div style={{ maxHeight: maxHeight["Gyldig til"] }}>
        <Slider
          className={styles.sliderstyle}
          type="range"
          id="validTo"
          min={currentYear}
          max={data.validTo}
          step={1}
          value={newFilters.validTo || currentYear}
          onChange={(val) =>
            setNewFilters((prev) => ({ ...prev, validTo: parseInt(val, 10) }))
          }
          number={newFilters.validTo || currentYear}
        />
      </div>
    );
  };



  const renderSafetyLevelFilter = () => (
    <div style={{ maxHeight: maxHeight["Safety Level"] }}>
      <Slider
        label="Safety Level"
        useScientific={false}
        value={newFilters.safetyLevelMax ?? 0}
        id="safetyLevelMax"
        onChange={(val) =>
          setNewFilters((prev) => ({
            ...prev,
            safetyLevelMax: parseInt(val, 10),
          }))
        }
        min={0}
        max={3}
        step={1}
        number={newFilters.safetyLevelMax ?? 0}
      />
    </div>
  );

  const renderCertificationFilter = () => {
    if (!data?.certifications?.length) return null;
    const items = Array.from(new Set(data.certifications));
    return (
      maxHeight.Certifikationer !== "0" && (
        <div
          className={styles.certificationDropdown}
          style={{ maxHeight: maxHeight.Certifikationer, overflow: "hidden" }}
        >
          {items.map((item) => (
            <div key={item} className={styles.producercheckbox}>
              <input
                type="checkbox"
                id={`certification-${item}`}
                name={`certification-${item}`}
                checked={newFilters.certifications.includes(item)}
                disabled
              />
              <label
                htmlFor={`certification-${item}`}
                className={styles.disabledLabel}
              >
                {certificationMap[item]} (Coming Soon)
              </label>
            </div>
          ))}
        </div>
      )
    );
  };

  const renderLandFilter = () => {
    // Use countryCodes since our parsed object is named that
    if (!data?.countryCodes) return null;
    const items = Array.from(new Set(data.countryCodes));
    if (items.length === 0) return null; // Ensuring no empty div renders

    return (
      <div style={{ maxHeight: maxHeight.Land, overflow: "hidden" }}>
        {items.map((item) => (
          <div key={item} className={styles.producercheckbox}>
            <Checkbox
              name={item}
              checked={newFilters.countryCodes.includes(item)}
              onChange={() =>
                setNewFilters((prev) => ({
                  ...prev,
                  countryCodes: prev.countryCodes.includes(item)
                    ? prev.countryCodes.filter((c) => c !== item)
                    : [...prev.countryCodes, item],
                }))
              }
              label={shortToLongUnit(item)}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderFilterContent = (category) => {
    switch (category) {
      case "GWP":
        return renderGWPFilter();
      case "Gyldig til":
        return renderValidToFilter();
      case "Producent":
        return renderProducerFilter();
      case "Standard":
        return renderComplianceFilter();
      case "Material":
        return renderMaterialsFilter();
      case "Land":
        return renderLandFilter();
      case "Brandklasse":
        return renderBrandFilter();
      case "Certifikationer":
        return renderCertificationFilter();
      case "Safety Level":
        return renderSafetyLevelFilter();
      default:
        return null;
    }
  };

  const filterCategories = [
    "GWP",
    "Standard",
    "Producent",
    "Material",
    "Land",
    "Gyldig til",
    "Safety Level",
    "Certifikationer",
    "Brandklasse",
  ];

  return (
    <div id="filter-query-container" className={styles.filter}>
      <div style={{ display: "flex" }}>
        <button className={styles.resetbutton} onClick={resetFilters}>
          Reset
        </button>
        <button className={styles.resetbutton} onClick={handleSearch}>
          Search
        </button>
      </div>
      {filterCategories.map((cat) => (
        <FilterCategory
          key={cat}
          categoryName={cat}
          isActive={activeFilterCategory === cat}
          onClickHandler={toggleCategory}
        >
          {renderFilterContent(cat)}
        </FilterCategory>
      ))}
    </div>
  );
}

export default FilterQuery;
