import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination";
import {
  fetchFromReg,
  fetchSubcategories,
  fetchAllCategories,
} from "../../components/APIS/categoryAPI";
import { fetchProducers } from "../../components/APIS/producerAPI";
import { AnimatePresence, motion } from "framer-motion";
import RadioButtonsGroup from "../../components/radiobuttongroup";
import styles from "./KategoriPage.module.css";
import Loader from "../../components/Utils/Loader";
import { SearchBarPopup } from "../../components/SwitchesAndStuff/searchCard";

// Debounce hook to limit rapid re-rendering on input changes
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
}

// 1) Define your custom category order (the ones you want first):
const CUSTOM_ORDER = [
  "Facade",
  "Indervæg",
  "Spærre",
  "Gulv",
  "Fundament",
  "Konstruktion",
  "Præfabrikerede Elementer",
  "Døre",
  "Vindue",
  "Shading",
  "Loft",
  "Tag",
  "MørtelPudsFuge",
  "Overfladebehandlinger",
  "Isolering",
  "Møbler",
  "Elektronik",
  "VejeOgStier",
  "Other",
];

function KategoriPage() {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Use search params for initial pagination & radio button value
  const initialPage = parseInt(searchParams.get("page") || "0", 10);
  const [page, setPage] = useState(initialPage);
  const [payingProducers, setPayingProducers] = useState([]);
  const [nonPayingProducers, setNonPayingProducers] = useState([]);
  const [producerPage, setProducerPage] = useState(initialPage);
  const itemsPerPage = 10;
  const initialRadioValue = searchParams.get("type") || "Konstruktioner";
  const [radioValue, setRadioValue] = useState(initialRadioValue);

  // Debounce the search input to reduce rapid updates
  const debouncedInput = useDebounce(inputValue, 300);

  // Update URL search params when radioValue, inputValue, or page changes
  useEffect(() => {
    setSearchParams({ type: radioValue, search: inputValue, page: page });
  }, [radioValue, inputValue, page, setSearchParams]);

  // Fetch categories and subcategories
  useEffect(() => {
    setIsLoading(true);
    Promise.all([fetchAllCategories(), fetchSubcategories()])
      .then(([categoriesData, subcategoriesData]) => {
        if (categoriesData) setCategories(categoriesData);
        if (subcategoriesData) setSubcategories(subcategoriesData);
      })
      .catch((error) => {
        console.error("Error fetching categories/subcategories:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // Fetch producers separately
  useEffect(() => {
    fetchProducers()
      .then((producersData) => {
        if (producersData) {
          setPayingProducers(producersData.payingNames || []);
          setNonPayingProducers(producersData.nonPayingNames || []);
        }
      })
      .catch((error) => {
        console.error("Error fetching producers:", error);
      });
  }, []);

  // Memoized function for filtering producers based on search term
  const searchProducers = useCallback(
    async (searchTerm) => {
      const allProducers = [...payingProducers, ...nonPayingProducers];
      return allProducers.filter(
        (producer) =>
          producer &&
          producer.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    [payingProducers, nonPayingProducers]
  );

  // Get image path for a given category
  const getImageByCategory = useCallback((category) => {
    const toCamelCase = (str) => {
      if (/^[a-z]+([A-Z][a-z]*)*$/.test(str)) return str;
      return str
        .toLowerCase()
        .replace(/[_\s-](.)/g, (_, group1) => group1.toUpperCase())
        .replace(/[_\s-]/g, "")
        .replace(/^(.)/, (_, group1) => group1.toLowerCase());
    };
    return `/images/kategori_billeder/${toCamelCase(category)}.svg`;
  }, []);

  const handleBoxClick = useCallback(
    (title) => {
      navigate(`/kategori/${title}`, { state: { category: title } });
    },
    [navigate]
  );

  const handleBoxsubClick = useCallback(
    (title) => {
      navigate(`/subkategori/${title}`, { state: { category: title } });
    },
    [navigate]
  );

  const formatSubcategory = useCallback((name) => {
    if (typeof name !== "string") return name;
    return name
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  }, []);

  const handleInputChange = (e) => setInputValue(e.target.value);

  // 2) Sort categories according to CUSTOM_ORDER
  const sortedCategories = useMemo(() => {
    if (!categories || categories.length === 0) return [];

    const categorySet = new Set(categories);
    // Filter to keep only items from CUSTOM_ORDER that are actually in `categories`
    const ordered = CUSTOM_ORDER.filter((cat) => categorySet.has(cat));

    // Anything not in CUSTOM_ORDER goes to the leftover array
    const leftover = categories.filter((cat) => !CUSTOM_ORDER.includes(cat));
    leftover.sort(); // Alphabetical sort for leftover

    return [...ordered, ...leftover];
  }, [categories]);

  // 3) Memoize the rendering of category boxes (using lazy loading for images)
  const boxes = useMemo(
    () =>
      sortedCategories.map((title, index) => (
        <div key={index} className={styles.box}>
          <img
            loading="lazy" // Native lazy loading enabled
            src={getImageByCategory(title)}
            className={styles.boxImg}
            alt={title}
            onClick={() => handleBoxClick(title)}
          />
          <h3 className={styles.boxTitle} onClick={() => handleBoxClick(title)}>
            {formatSubcategory(title)}
          </h3>
        </div>
      )),
    [sortedCategories, getImageByCategory, handleBoxClick, formatSubcategory]
  );

  // Memoize material content rendering
  const materialContent = useMemo(() => {
    if (!subcategories || subcategories.length === 0) return <p>No data</p>;
    return (
      <div className={styles.materialColumns}>
        {subcategories
          .filter(
            (material) =>
              typeof material === "string" &&
              !material.toLowerCase().startsWith("sub") &&
              material.trim()
          )
          .map((material, index) => (
            <div key={index} className={styles.materialColumn}>
              <p onClick={() => handleBoxsubClick(material)}>
                {formatSubcategory(material)}
              </p>
            </div>
          ))}
      </div>
    );
  }, [subcategories, handleBoxsubClick, formatSubcategory]);

  const handleProducerClick = useCallback(
    (producer) => {
      navigate(`/producer/${producer}`, {
        state: { producer },
      });
    },
    [navigate]
  );

  // Memoize producer content rendering with pagination
  const producerContent = useMemo(() => {
    const offset = producerPage * itemsPerPage;
    const currentItems = nonPayingProducers
      .filter((producer) => producer)
      .slice(offset, offset + itemsPerPage);

    const handlePageClick = (event) => {
      const newPage = event.selected;
      setPage(newPage);
      setProducerPage(newPage);
    };

    return (
      <>
        <div className={styles.producerGrid}>
          {payingProducers.map((producer) => (
            <div
              key={producer}
              className={styles.payingProducerCard}
              onClick={() => handleProducerClick(producer)}
            >
              <h3 className={styles.producerTitle}>{producer}</h3>
            </div>
          ))}
        </div>

        <div className={styles.nonPayingContainer}>
          <div className={styles.nonPayingProducerList}>
            {currentItems.map((producer) => (
              <div
                key={producer}
                className={styles.nonPayingProducerListItem}
                onClick={() => handleProducerClick(producer)}
              >
                <h3 className={styles.producerTitle}>{producer}</h3>
              </div>
            ))}
            <Pagination
              pageCount={Math.ceil(nonPayingProducers.length / itemsPerPage)}
              forcePage={page}
              onPageChange={handlePageClick}
            />
          </div>
        </div>
      </>
    );
  }, [
    producerPage,
    itemsPerPage,
    nonPayingProducers,
    payingProducers,
    page,
    handleProducerClick,
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputValue.trim()) {
      navigate(`/search/${encodeURIComponent(inputValue)}`);
    }
  };

  return (
    <div
      className={`${styles.mainContainer} ${isFocused ? styles.blurred : ""}`}
      style={{ paddingBottom: "20px" }}
    >
      {/* Dim overlay when search input is focused */}
      {isFocused && (
        <div
          className={styles.overlay}
          onClick={() => setIsFocused(false)}
        ></div>
      )}

      <div className={styles.pageKategoriContainer}>
        <img
          src="/images/hovedelogo.svg"
          alt="logo"
          className={styles.logoContainer}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            position: "relative",
            justifyContent: "center",
          }}
        >
          {radioValue === "Producenter" ? (
            <>
              <form style={{ width: "80%" }} onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Søg efter producenter"
                  className={styles.searchInput}
                  value={inputValue}
                  onChange={handleInputChange}
                  onFocus={() => setIsFocused(true)}
                />
              </form>

              <div
                style={{
                  position: "absolute",
                  top: "75%",
                  left: "50%",
                  width: "80%",
                  transform: "translateX(-50%)",
                  zIndex: "1000",
                }}
              >
                {inputValue !== "" && isFocused && (
                  <SearchBarPopup
                    query={searchProducers}
                    search={debouncedInput}
                    onSelect={(producer) => handleProducerClick(producer)}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <form style={{ width: "80%" }} onSubmit={handleSubmit}>
                <input
                  type="text"
                  placeholder="Søg efter produkter"
                  className={styles.searchInput}
                  value={inputValue}
                  onChange={handleInputChange}
                  onFocus={() => setIsFocused(true)}
                />
              </form>

              <div
                style={{
                  position: "absolute",
                  top: "75%",
                  left: "50%",
                  width: "80%",
                  transform: "translateX(-50%)",
                  zIndex: "1000",
                }}
              >
                {inputValue !== "" && isFocused && (
                  <SearchBarPopup
                    query={fetchFromReg}
                    search={debouncedInput}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.searchbarAndRadio}>
        <RadioButtonsGroup
          options={["Konstruktioner", "Materialer", "Producenter"]}
          selectedValue={radioValue}
          handleRadioChange={(e) => setRadioValue(e.target.value)}
        />
      </div>

      <AnimatePresence mode="wait">
        {radioValue === "Konstruktioner" ? (
          <motion.div
            key="konstruktioner"
            style={{ paddingTop: "15px" }}
            className={styles.boxesContainer}
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.3 }}
          >
            {isLoading ? <Loader /> : boxes}
          </motion.div>
        ) : radioValue === "Materialer" ? (
          <motion.div
            key="materialContent"
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            {isLoading ? <Loader /> : materialContent}
          </motion.div>
        ) : radioValue === "Producenter" ? (
          <motion.div
            key="producentContent"
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.3 }}
          >
            {isLoading ? <Loader /> : producerContent}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
}

export default KategoriPage;
