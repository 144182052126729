import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Styles from "./vilkaar.module.css";
import { vilkarText } from "./text/vilkar";
import { privacyText } from "./text/Privacy";

function Vilkaar() {
  // Read the initial activeTab from URL query params (default to "PrivacyPolicy")
  const [searchParams, setSearchParams] = useSearchParams();
  const initialTab = searchParams.get("activeTab") || "PrivacyPolicy";
  const [activeTab, setActiveTab] = useState(initialTab);

  // Define available sections
  const policies = {
    PrivacyPolicy: privacyText.HelpPageContents.PrivacyPolicy,
    TermsAndConditions: vilkarText.HelpPageContents.TermsAndConditions,
  };

  const currentPolicy = policies[activeTab];

  // Function to format text: Convert **bold** to <strong>bold</strong>
  const formatText = (text) => {
    if (!text) return "";
    return text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
  };

  // Update URL query params when activeTab changes
  useEffect(() => {
    setSearchParams({ activeTab });
  }, [activeTab, setSearchParams]);

  // Handle missing data gracefully
  if (!currentPolicy) {
    return (
      <div className={Styles.error}>
        Error: Policy content not available.
      </div>
    );
  }

  return (
    <div className={Styles.vilkaarContainer}>
      {/* Tab Navigation */}
      <div className={Styles.tabContainer}>
        {Object.entries(policies).map(([key, policy]) => (
          policy && (
            <button
              key={key}
              className={`${Styles.tabButton} ${
                activeTab === key ? Styles.activeTab : ""
              }`}
              onClick={() => setActiveTab(key)}
            >
              {policy.label}
            </button>
          )
        ))}
      </div>

      {/* Policy Content */}
      <div className={Styles.vilkaarContent}>
        <h2>{currentPolicy.label}</h2>
        {currentPolicy.subcategories.map(({ key }) => {
          const section = currentPolicy.content[key];
          return (
            section && (
              <div key={key} className={Styles.section}>
                <h3>{section.label}</h3>
                {section.paragraphs.map((para, idx) => (
                  <p
                    key={idx}
                    dangerouslySetInnerHTML={{
                      __html: formatText(para.text),
                    }}
                  ></p>
                ))}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default Vilkaar;