

function UnitTranslator (unit) {


        const unitMap = {
            "kg CO2 eqv.": ["kg CO2 eqv.", "kg CO2-eqv.", "kg CO_(2) eq", "kg CO_(2) eqv.", "kg CO2-Äqv.", "kg CO_(2)-Äq."],
            "kg CFC11 eqv.": ["kg CFC11 eq", "kg CFC11 eqv.", "kg CFC 11 eqv.", "kg CFC 11-Äquiv.", "kg CFC11-Äq.", "kg CFC 11 eq."],
            "kg PO4 eqv.": ["kg PO4 eq", "kg PO_(4)^(3) eq", "kg PO_(4)^(3)-Äq.", "kg Phosphate-eqv.", "kg Phosphat-Äqv.", "kg P eqv.", "kg P eq", "kg P-Äq."],
            "mol H+ eqv.": ["mol H+ eqv.", "mol H^(+) eqv.", "mol H^(+)-Äq.", "mol H+ eq"],
            "m³ world eqv. deprived": ["m³ world eqv. deprived", "m³ world eqv.", "m³ world-Äq. deprived", "m3 world eqv.", "m³ Welt-Äq. entzogen"],
            "kg NMVOC eqv.": ["kg NMVOC eq.", "kg NMVOC eqv.", "kg NMVOC-Äq."],
            "kg N eqv.": ["kg N eq.", "kg N eqv.", "kg N-Äq."],
            "CTUh": ["CTUh", "CTUe", "disease incidence", "Krankheitsfälle", "Disease incidence"],
            "kg": ["kg"],
            "MJ": ["MJ"],
            "SQP": ["SQP"],
            "m³": ["m3", "m³","m^(3)", "m^3"],
            "m²": ["m2", "m²", "m^(2)", "m^2"],
            "kg Sb eqv.": ["kg Sb-Äqv.", "kg Sb eq", "kg Sb-Äq.","kg Sb-Äqv."],	
            "kg Ethen eqv." : ["kg Ethen eqv.", "kg Ethen-Äqv.", "kg Ethen-Äq.", "kg Ethen eq."],
            "kg SO2 eqv." : ["kg SO2 eqv.", "kg SO2-Äqv.", "kg SO2-Äq.", "kg SO2 eq."],
            "kg PO4 eqv." : ["kg PO4 eqv.", "kg PO4-Äqv.", "kg PO4-Äq.", "kg PO4 eq."],
            
          };
    
    

    
      // Normalize the input unit
      for (const [correctUnit, variations] of Object.entries(unitMap)) {
        if (variations.includes(unit)) {
          return correctUnit;
        }
      }
      
      return unit;
    }
export default UnitTranslator;


export const certificationMap = {
  1: "CE",
  2: "Svanemarke",
  3: "Cradle To Cradle",
  4: "Indeklimamærket",
  5: "FSC",
  6: "PEFC",
  7: "EU Ecolabel",
  8: "The Blue Angel",
  9: "Astma Danmark",
  10: "Allergy uk",
};


export const certificationImages = {
  1: '/images/CE.svg',
  2: '/images/svanemærke.svg',
  3: '/images/CradletoCradle.svg',
  4: '/images/indeklimamaerke.svg',
  5: '/images/fsc-maerket.svg',
  6: '/images/PEFC.svg',
  7: '/images/euecolabelpng.png',
  8: '/images/Blauer_Engel_logo.svg',
  9: '/images/astmadanmark.jpg',
  10: '/images/allergy_uk.jpg',
};
  
export const debounce = (func, delay) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
};

export const shortToLongUnit = (cc) => {

const country_codes = {
    "SE": "Sverige",
    "US": "United States",
    "DK": "Denmark",
    "NO": "Norway",
    "DE": "Germany",
    "FR": "France",
    "CN": "China",
    "JP": "Japan",
    "IN": "India",
    "FI": "Finland",
    "UK": "United Kingdom",
    "IT": "Italy",
    "ES": "Spain",
    "AU": "Australia",
    "NZ": "New Zealand",
    "BR": "Brazil",
    "MX": "Mexico",
    "EU-27": "European Union (27 countries)",
    "EU-25": "European Union (25 countries)",
    "FK": "Falkland Islands",
    "RSA": "South Africa",
    "RME": "Regional Middle East",
    "WEU": "Western Europe",
    "QA": "Qatar",
    "KW": "Kuwait",
    "TH": "Thailand",
    "RO": "Romania",
    "SG": "Singapore",
    "SI": "Slovenia",
    "UA": "Ukraine",
    "HU": "Hungary",
    "HR": "Croatia",
    "BG": "Bulgaria",
    "TW": "Taiwan",
    "SCAND": "Scandinavia",
    "EE": "Estonia",
    "NL": "Netherlands",
    "NOF": "Northern France",
    "BZ": "Belize",
    "CO": "Colombia",
    "GLO": "Global",
    "RAF": "Regional Africa",
    "IS": "Iceland",
    "NORD": "Nordic Countries",
    "UZ": "Uzbekistan",
    "IL": "Israel",
    "RLA": "Regional Latin America",
    "GR": "Greece",
    "PT": "Portugal",
    "GA": "Gabon",
    "EG": "Egypt",
    "IE": "Ireland",
    "PL": "Poland",
    "ID": "Indonesia",
    "OM": "Oman",
    "SA": "Saudi Arabia",
    "SK": "Slovakia",
    "GB": "Great Britain",
    "AE": "United Arab Emirates",
    "de": "Germany (de)",
    "CZ": "Czech Republic",
    "CL": "Chile",
    "LB": "Lebanon",
    "MA": "Morocco",
    "RNA": "Regional North America",
    "CY": "Cyprus",
    "Europe": "Europe",
    "BH": "Bahrain",
    "BJ": "Benin",
    "LU": "Luxembourg",
    "MU": "Mauritius",
    "BA": "Bosnia and Herzegovina",
    "SEA": "Southeast Asia",
    "LV": "Latvia",
    "BE": "Belgium",
    "MY": "Malaysia",
    "ZA": "South Africa",
    "RAS": "Regional Asia",
    "SOA": "South Asia",
    "TR": "Turkey",
    "AT": "Austria",
    "VN": "Vietnam",
    "KR": "South Korea",
    "LT": "Lithuania",
    "AR": "Argentina",
    "RER": "Regional Europe",
    "OCE": "Oceania",
    "CH": "Switzerland",
};

  return country_codes[cc];
}