import React, { useState } from 'react';
import styles from './RegisterPage.module.css';
import RegisterForm from './RegisterForm';

function StudentRegisterPage() {
    const [formData, setFormData] = useState({
        Firstname: '',
        Lastname: '',
        StudentID: '',
        School: '',
        Email: '',
        password: '',
        repeatPassword: ''
    });

    const inputFields = [
        { name: 'email', type: 'text', placeholder: 'E-mail' },
        { name: 'firstname', type: 'text', placeholder: 'Fornavn' },
        { name: 'lastname', type: 'text', placeholder: 'Efternavn' },
        { name: 'school', type: 'text', placeholder: 'Skole' },
        { name: 'studentID', type: 'text', placeholder: 'Studie-ID' },
        { name: 'password', type: 'password', placeholder: 'Adgangskode' },
        { name: 'repeatPassword', type: 'password', placeholder: 'Gentag adgangskode' }
    ];

    return (
        <div className={styles.pageStyle}>
            <h2>Registrering - Studerende</h2>
            <RegisterForm inputFields={inputFields} stud={true} />
        </div>
    );
}

export default StudentRegisterPage;
