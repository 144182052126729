import React from "react";
import ReactPaginate from "react-paginate";
import styles from "./Pagination.module.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const Pagination = ({ pageCount,forcePage ,onPageChange }) => {
  return (
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      onPageChange={onPageChange}

      containerClassName={styles.pagination}
      pageClassName={styles.pageItem}
      pageLinkClassName={styles.pageLink}

      forcePage={forcePage}


      previousClassName={styles.pageItem}
      nextClassName={styles.pageItem}
      activeClassName={styles.active}
      
      breakClassName={styles.breakItem}
      breakLinkClassName={styles.breakLink}


      previousLabel={<IoIosArrowBack />}
      nextLabel={<IoIosArrowForward />}
    />
  );
};

export default Pagination;
