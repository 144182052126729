
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./HelpPage.module.css";
import RadioButtonsGroup from "../../components/radiobuttongroup";
import { helpText } from "./helpText";
import { GrCircleInformation } from "react-icons/gr";


const TooltipImage = ({ imageUrl }) => (
  <span className={styles.tooltip}>
    {/* This trigger text or icon can be adjusted as needed */}
    <span className={styles.tooltipTrigger}>
        <GrCircleInformation color="var(--Sand)" size={16} />
       </span>
    <span className={styles.tooltipContent}>
      <img
        src={imageUrl}
        alt="Tooltip"
        style={{ maxWidth: "500px", height: "auto", display: "block" }}
      />
    </span>
  </span>
);


const categories = Object.keys(helpText.HelpPageContents || {}).reduce(
  (acc, key) => ({
    ...acc,
    [key]: helpText.HelpPageContents[key]?.label || key,
  }),
  {}
);

const subcategories = Object.keys(helpText.HelpPageContents || {}).reduce(
  (acc, category) => ({
    ...acc,
    [category]:
      helpText.HelpPageContents[category]?.subcategories?.map((sub) => ({
        key: sub.key,
        label:
          helpText.HelpPageContents[category]?.content?.[sub.key]?.label ||
          sub.key,
      })) || [],
  }),
  {}
);


const defaultCategory = Object.keys(categories)[0] || "";
const defaultSubcategory = subcategories[defaultCategory]?.[0]?.key || "";

function HelpPage() {
  // Initialize search params from the URL
  const [searchParams, setSearchParams] = useSearchParams();
  const initialCategory = searchParams.get("category") || defaultCategory;
  const initialSubcategory =
    searchParams.get("subcategory") || defaultSubcategory;

  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    initialSubcategory
  );

  // Update URL search params whenever the selected category or subcategory changes
  useEffect(() => {
    setSearchParams({
      category: selectedCategory,
      subcategory: selectedSubcategory,
    });
  }, [selectedCategory, selectedSubcategory, setSearchParams]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(subcategories[category]?.[0]?.key || "");
  };
// Helper function to compute reference mapping and list
const computeReferenceMapping = (content) => {
  let mapping = {};
  let refList = [];
  if (!content) return { mapping, refList };

  // Process paragraphs
  if (Array.isArray(content.paragraphs)) {
    content.paragraphs.forEach((para) => {
      if (para.reference) {
        if (!mapping[para.reference]) {
          mapping[para.reference] = refList.length + 1;
          refList.push(para.reference);
        }
      }
    });
  }

  // Process additional paragraphs
  if (Array.isArray(content.additionalParagraphs)) {
    content.additionalParagraphs.forEach((para) => {
      if (para.reference) {
        if (!mapping[para.reference]) {
          mapping[para.reference] = refList.length + 1;
          refList.push(para.reference);
        }
      }
    });
  }
  return { mapping, refList };
};

const renderContent = (content) => {
  if (!content) return <p>Ingen indhold tilgængelig.</p>;
  if (typeof content === "string") return <p>{content}</p>;

  // Compute reference mapping from content (available for inline markers and footnotes)
  const { mapping: refMapping, refList } = computeReferenceMapping(content);

  return (
    <div>
      {content.label && <h3>{content.label}</h3>}
      {content.text && <p>{content.text}</p>}



      {/* Render list after paragraphs */}
      {content.list && Array.isArray(content.list) && (
        <ul>
          {content.list.map((item, index) => (
            <li key={index}>
              {item.title && <p>{item.title}</p>}
              {item.text && (
                <ul>
                  <li>{item.text}</li>
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}

      {/* Render numbered list after paragraphs */}
      {content.numberedList && Array.isArray(content.numberedList) && (
        <ol>
          {content.numberedList.map((item, index) => (
            <li key={index}>
              {item.title && <strong>{item.title}</strong>}
              {item.text && (
                <ul>
                  <li>{item.text}</li>
                </ul>
              )}
            </li>
          ))}
        </ol>
      )}

      {/* Render additional paragraphs */}
{content.paragraphs?.map((paragraph, index) => (
  <div key={index}>
    {paragraph.title ? (
      <p style={{ fontWeight: "bolder" }}>
        {paragraph.title}
        {/* Tooltip renders immediately after the title */}
        {paragraph.tooltip && <TooltipImage imageUrl={paragraph.tooltip} />}
      </p>
    ) : null}

    {paragraph.text && (
      <p>
        {paragraph.text}
        {/* If there's no title and a tooltip exists, render it before the reference */}
        {(!paragraph.title && paragraph.tooltip) && (
          <TooltipImage imageUrl={paragraph.tooltip} />
        )}
        {paragraph.reference && refMapping[paragraph.reference] && (
          <sup className={styles.inlineReference}>
            <a href={`#ref-${refMapping[paragraph.reference]}`}>
              [{refMapping[paragraph.reference]}]
            </a>
          </sup>
        )}
      </p>
    )}

    {paragraph.link && (
      <p>
        <a href={paragraph.link} target="_blank" rel="noopener noreferrer">
          Læs mere
        </a>
      </p>
    )}

    {paragraph.image && paragraph.image.src && (
      <div
        className={styles.imageContainer}
        style={{ maxWidth: paragraph.image.maxWidth || "100%" }}
      >
        <img
          src={paragraph.image.src}
          alt={paragraph.title || "Image"}
          className={styles.helpImage}
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </div>
    )}
  </div>
))}
   {/* Render additional paragraphs once */}
    {content.additionalParagraphs && content.additionalParagraphs.map((item, idx) => {
      return <p key={idx}>{item.text}</p>;
    })}
  </div>
);
};


      
  // Helper to extract references from content
  const extractReferences = (content) => {
    const refs = [];
    if (!content) return refs;
    if (content.paragraphs && Array.isArray(content.paragraphs)) {
      content.paragraphs.forEach((para) => {
        if (para.reference) {
          refs.push(para.reference);
        }
      });
    }
    if (
      content.additionalParagraphs &&
      Array.isArray(content.additionalParagraphs)
    ) {
      content.additionalParagraphs.forEach((para) => {
        if (para.reference) {
          refs.push(para.reference);
        }
      });
    }
    return refs;
  };

  // Get the current content and its references
  const currentContent =
    helpText.HelpPageContents[selectedCategory]?.content?.[selectedSubcategory];
  const references = extractReferences(currentContent);

  return (
    <div className={styles.helpPageContainer}>
      <h2>Find hjælp til EPD'er</h2>
      {/* Uncomment and adjust if you want to use radio buttons for categories */}
      {/* <div className={styles.toggleContainer}>
        <RadioButtonsGroup
          options={Object.entries(categories).map(([key, label]) => ({
            key,
            label,
          }))}
          selectedValue={selectedCategory}
          handleRadioChange={(e) => handleCategoryChange(e.target.value)}
        />
      </div> */}
      <div className={styles.mainContent}>
        <div className={styles.sideBar}>
          <h3 className={styles.sideBarHeader}>Oversigt</h3>
          {subcategories[selectedCategory]?.map((sub) => (
            <button
              key={sub.key}
              className={
                styles.subcategoryButton +
                (sub.key === selectedSubcategory ? ` ${styles.active}` : "")
              }
              onClick={() => setSelectedSubcategory(sub.key)}
            >
              {sub.label}
            </button>
          ))}
        </div>

        <div className={styles.contentContainer}>
          {renderContent(currentContent)}
      {/* Render references at the bottom if any exist */}
      {references.length > 0 && (
        <div className={styles.referencesContainer}>
          <ol className={styles.referencesList}>
            {references.map((ref, idx) => (
              <li key={idx} id={`ref-${idx + 1}`}>{ref}</li>                
            ))}
          </ol>
        </div>
      )}
        </div>
      </div>

    </div>
  );
}

export default HelpPage;
