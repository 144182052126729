export const privacyText = {
  HelpPageContents: {
    PrivacyPolicy: {
      label: "Privacy Policy",
      subcategories: [
        { key: "DataController" },
        { key: "Contact" },
        { key: "Purpose" },
        { key: "DataProcessing" },
        { key: "RetentionPeriod" },
        { key: "DataTransfer" },
        { key: "IndividualRights" },
      ],
      content: {
        DataController: {
          label: "Data Controller",
          paragraphs: [
            {
              text: "Buildtivity ApS, VAT no. DK43976486, Hadsundvej 86C, 2. tv, 9000 Aalborg, Denmark is the data controller for processing your personal data. In this document, '**we**' or '**us**' refers to Buildtivity ApS.",
            },
          ],
        },
        Contact: {
          label: "Contact",
          paragraphs: [
            {
              text: "If you have any questions or wish to exercise your individual rights as described below, please contact us at info@buildtivity.dk.",
            },
          ],
        },
        Purpose: {
          label: "Purpose of Data Processing",
          paragraphs: [
            {
              text: "We process your personal data to register your user account, provide you with access to the Buildtivity platform, and for invoicing purposes.",
            },
          ],
        },
        DataProcessing: {
          label: "Types of Personal Data Processed & Legal Basis",
          paragraphs: [
            {
              text: "We process your identification information to know who we are dealing with, your contact details to communicate with you and a password for you to enable a secure login (all based on article 6(1)(b) GDPR because processing is necessary to provide access to the platform and provide you important messages about the service and your account). ",
            },
            {
              text: "We also need to know at which company you are employed to make sure your account is bundled correctly with user accounts from the same company to, for instance, apply discounts, handle invoicing etc. (based on article 6(1)(f) GDPR because it is in our legitimate interest to comply with the agreements made with your employer and since we assess that your legitimate interests do not outweigh ours in this regard).",
            },
          ],
        },
        RetentionPeriod: {
          label: "Retention Period",
          paragraphs: [
            {
              text: "We will retain your personal data while your account is active. If there has been no activity on your account for one year, we will send you a reminder to hear if you are still interested in maintaining your account. If we do not hear from you within six months from then, we will anonymise your account and your personal data.",
            },
          ],
        },
        DataTransfer: {
          label: "Data Transfer",
          paragraphs: [
            {
              text: "We will not share your personal data with third parties but use a data processor for hosting of user data located in the EU.",
            },
          ],
        },
        IndividualRights: {
          label: "Your Individual Rights",
          paragraphs: [
            {
              text: "**Individual rights**: You have the rights outlined below which you can exercise by contacting us using the details provided above. Your request will be processed free of charge and as quickly as possible, and no later than one month after we receive it, except if the request is complex or numerous, then it may take up to two months.",
            },
            {
              text: "**Withdraw consent**: If we process your data based on your consent, you can withdraw it at any time by contacting us. This will not affect the processing that happened before the withdrawal.", 
            },
            {
              text: "**Right of access**: You have the right to access your personal data and certain details about how we process it. However, access may be restricted if it involves information that must remain confidential due to public or private interests.",
            },
            {
              text: "**Right to rectification**: You have the right to correct any inaccurate personal data or to complete any incomplete information about you.",
            },
            {
              text: "**Right to erasure**: You can request the deletion of your personal data in certain situations, such as when the data is no longer needed or when you withdraw consent for processing.",
            },
            {
              text: "**Right to restrict processing**: Under certain conditions (eg if you contest the accuracy of your data), you can request that the processing of your data be restricted while we verify its accuracy.",
            },
            {
              text: "**Right to object**: You have the right to object to the processing of your personal data if it is based on our legitimate interests (article 6(1)(f) GDPR). You can also object to processing for direct marketing purposes at any time.",
            },
            {
              text: "**Data portability**: If the processing is based on consent or a contract, you have the right to receive your data in a structured, commonly used format, and to transfer it to another controller. If you prefer, we can transfer it directly, provided it is technically feasible.",
            },
            {
              text: "**Automated decision-making**: You have the right not to be subject to decisions made solely by automated processes, including profiling, if these decisions have legal or similarly significant effects on you.",
            },
            {
              text: "**Right to lodge a complaint**: You can file a complaint about our processing of your personal data at any time. Additionally, you may lodge a complaint with the Danish Data Protection Agency (dt@datatilsynet.dk) or the supervisory authority in your country of residence or where the data breach occurred.",
            },
          ],
        },
      },
    },
  },
};
