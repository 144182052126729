import { IoIosArrowDown } from "react-icons/io";
import styles from "./FilterCategory.module.css";
import React,{ useState } from "react";

// function FilterCategory({ categoryName, isActive, onClickHandler, children }) {
//   return (
//     <div className={styles.filterCategoryContainer}>
//       {categoryName === "GWP" ? (
//         <div
//           className={styles.filterCategory}
//           onClick={() => onClickHandler(categoryName)}
//         >
//           {categoryName} <p>(Global Warming Potential)</p>
//           <IoIosArrowDown
//             className={`${styles.arrowIcon} ${isActive ? styles.active : ""}`}
//           />
//         </div>
//       ) : categoryName === "Levetid" ? (
//         <div
//           className={styles.filterCategory}
//           onClick={() => onClickHandler(categoryName)}
//         >
//           {categoryName} <p>(RSL)</p>
//           <IoIosArrowDown
//             className={`${styles.arrowIcon} ${isActive ? styles.active : ""}`}
//           />
//         </div>
//       ) : (
//         <div
//           className={styles.filterCategory}
//           onClick={() => onClickHandler(categoryName)}
//         >
//           {categoryName}{" "}
//           <IoIosArrowDown
//             className={`${styles.arrowIcon} ${isActive ? styles.active : ""}`}
//           />
//         </div>
//       )}
//       <div
//         className={`${styles.filterContent} ${
//           isActive ? styles.expand : styles.collapse
//         }`}
//       >
//         {isActive && children}
//       </div>
//     </div>
//   );
// }

// export default FilterCategory;


function FilterCategory({ categoryName, isActive, onClickHandler, children }) {
  const isDisabled = categoryName === "Certifikationer" || categoryName === "Brandklasse" ;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={styles.filterCategoryContainer}>
      <div
        className={`${styles.filterCategory} ${isDisabled ? styles.disabledCategory : ""}`}
        onClick={() => !isDisabled && onClickHandler(categoryName)}
        onMouseEnter={() => isDisabled && setIsHovered(true)}
        onMouseLeave={() => isDisabled && setIsHovered(false)}
      >
        {/* If hovered and disabled, show "Kommer snart" instead of the normal text */}
        {isHovered && isDisabled ? (
          <span className={styles.comingSoonText}>Kommer snart</span>
        ) : (
          <>
            {/* Special cases for GWP and Levetid */}
            {categoryName === "GWP" ? (
              <>
                <span className={styles.categoryName}>{categoryName}</span>
                <p className={styles.categoryDescription}>(Global Warming Potential)</p>
              </>
            ) : categoryName === "Levetid" ? (
              <>
                <span className={styles.categoryName}>{categoryName}</span>
                <p className={styles.categoryDescription}>(RSL)</p>
              </>
            ) : (
              <span className={styles.categoryName}>{categoryName}</span>
            )}

            {/* Arrow icon (Hidden if "Kommer snart" is shown) */}
            {!isHovered && (
              <IoIosArrowDown
                className={`${styles.arrowIcon} ${isActive ? styles.active : ""}`}
              />
            )}
          </>
        )}
      </div>

      {/* Render filter content only if not disabled */}
      {!isDisabled && (
        <div
          className={`${styles.filterContent} ${
            isActive ? styles.expand : styles.collapse
          }`}
        >
          {isActive && children}
        </div>
      )}
    </div>
  );
}

export default FilterCategory;
