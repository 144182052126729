import React from "react";

const RadioButtonsGroup = ({ options, selectedValue, handleRadioChange }) => {
  const tworadiosystem = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    borderRadius: "30px",
    cursor: "pointer",
    background: "var(--White)",
    color: "var(--Black)",
    margin: "5px",
    textAlign: "center",
    whiteSpace: "pre-line",
  };

  const tworadiosystemSelected = {
    ...tworadiosystem,
    background: "var(--Sand)",
    color: "var(--White)",
  };

  const radioButtonsContainerStyle = {
    display: "flex",
    border: "4px solid",
    borderRadius: "30px",
    borderColor: "var(--Sand)",
    justifyContent: "center",
  };

  return (
    <div style={radioButtonsContainerStyle}>
      {options.map((option, index) => {
        // This wouldnt let Check if option is an object with `key` and `label`
        const isObject = typeof option === "object" && option !== null;
        const key = isObject ? option.key : option; // Use key if object, else use option directly
        const label = isObject ? option.label : option; // Use label if object, else use option directly

        return (
          <label
            key={index}
            style={
              selectedValue === key ? tworadiosystemSelected : tworadiosystem
            }
          >
            <input
              type="radio"
              value={key}
              checked={selectedValue === key}
              onChange={handleRadioChange}
              style={{ display: "none" }}
            />
            {label.split("\n").map((line, i) => (
              <React.Fragment key={i}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </label>
        );
      })}
    </div>
  );
};

export default RadioButtonsGroup;
