import { useParams, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  fetchDatafromQuery,
  fetchQueryDataCat,
  fetchQueryDataReg,
  fetchFromReg,
} from "../../../components/APIS/categoryAPI";
import MemoizedDetailBoxes from "../LoadedProducts";
import FilterQuery from "../Filter/Filter";
import Pagination from "../../../components/Pagination";
import FilterSortButton from "../filterSortButton/filterSortButton";
import UnitTranslator from "../../../components/Utils/Helpers";
import { SearchBarPopup } from "../../../components/SwitchesAndStuff/searchCard";
import Loader from "../../../components/Utils/Loader";
import styles from "./kategoridetailstyle.module.css";

function KategoriDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // Determine page type from URL
  const isSubkategori = location.pathname.includes("/subkategori/") || location.search.includes("subCategories");
  const isSearch = location.pathname.includes("/search")|| location.search.includes("regex");
  const isProducer = location.pathname.includes("/producer") || location.search.includes("producers");

  // State declarations
  const [isLoading, setIsLoading] = useState(true);
  const [productLoad, setProductLoad] = useState(true);
  const [products, setProducts] = useState([]);
  const [selectedData, setSelectedData] = useState(null); // formerly `dat`
  const [orgData, setOrgData] = useState([]);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [unit, setUnit] = useState(null);
  const [title, setTitle] = useState("");
  const [query, setQuery] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [page, setPage] = useState(() => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("pn")) || 1;
  });
  const [maxPage, setMaxPage] = useState(100);

  // Helper: Format a subcategory title
  const formatSubcategory = (name) =>
    name
      .replace(/ae/g, "æ")
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());

  // Set page title based on URL type
  useEffect(() => {
    if (isSubkategori) {
      setTitle(formatSubcategory(id).replace(/-/g, " "));
    } else {
      setTitle(id);
    }
  }, [id, isSubkategori]);

  // Fetch initial data on load
  const fetchInitialData = async () => {
    try {
      let data = [];
      if (isSubkategori) {
        // For subcategories, replace dashes with spaces
        const formattedId = id.replace(/-/g, " ");
        data = await fetchQueryDataCat({ id: formattedId });
      } else if (isSearch) {
        const response = await fetchQueryDataReg(id, 0);
        data = response.filterData;
      } else {
        // For categories, try with first-letter uppercase
        const originalID = id.charAt(0).toUpperCase() + id.slice(1);
        data = await fetchQueryDataCat({ id: originalID });
        if (Array.isArray(data) && data.length === 0) {
          // Try again with an encoded space if no results found
          const spaceID = (originalID);
          data = await fetchQueryDataCat({ id: spaceID });
        }
      }
      setOrgData(data);

      // Process data to set available units and default selection
      if (Array.isArray(data)) {
        const units = [...new Set(data.map((item) => item.unit))];
        setAvailableUnits(units);
        const defaultUnit = units.includes("kg")
          ? "kg"
          : units[Math.floor(Math.random() * units.length)];
        setUnit(defaultUnit);
        const filtered = data.filter((item) => item.unit === defaultUnit);
        setSelectedData(filtered[0] || null);
      } else if (data && typeof data === "object") {
        setAvailableUnits(data.unit ? [data.unit] : []);
        setUnit(data.unit || null);
        setSelectedData(data);
      } else {
        console.warn("Unexpected data format:", data);
        setAvailableUnits([]);
        setUnit(null);
        setSelectedData(null);
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [id, isSubkategori, isSearch]);

  // Handle unit change
  const handleUnitChange = (newUnit) => {
    if (Array.isArray(orgData)) {
      const filtered = orgData.filter((item) => item.unit === newUnit);
      setUnit(newUnit);
      setSelectedData(filtered[0] || null);
      setPage(1);
    }
  };

  // Render the unit selection box
  const renderUnitBox = () => (
    <div className={styles.unitBox}>
      {availableUnits.map((unitOption) => (
        <div
          key={unitOption}
          onClick={() => handleUnitChange(unitOption)}
          className={`${styles.unitOption} ${
            unit === unitOption ? styles.selected : ""
          }`}
        >
          {UnitTranslator(unitOption)}
        </div>
      ))}
    </div>
  );

  // Handle pagination changes
  const handlePageClick = ({ selected }) => setPage(selected + 1);

  // Fetch products when query or page changes
  const fetchProducts = async () => {
    // Update URL parameters
    const params = new URLSearchParams(location.search);
    params.set("pn", page);

    
    if (searchInput.trim() !== "") {
      params.set("regex", encodeURIComponent(searchInput));
    } else {
      params.delete("regex");
    }

    const newSearch = params.toString();
    if (location.search !== `?${newSearch}`) {
      navigate(`${location.pathname}?${newSearch}`, { replace: true });
    }

    setIsLoading(true);

    if (Object.keys(query).length > 0) {
      try {
        const updatedQuery = {
          ...query,
          isSubcategory: isSubkategori,
          isProducer:
            isProducer ||
            (Array.isArray(query.producers) && query.producers.length > 0),
          isCategory: !isSubkategori && !isSearch && !isProducer,
          queryVariable: isSearch ? "" : id,
          ...(isSearch ? { regex: id } : {}),
          ...(searchInput.trim() !== ""
            ? { regex: encodeURIComponent(searchInput) }
            : {}),
          pageNumber: page,
        };
        const response = await fetchDatafromQuery(updatedQuery, isSubkategori);
        setProducts(response.data);
        console.log(updatedQuery);
        setMaxPage(response.maxPageNumber);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
        setProductLoad(false);
      }
    }
  };
  useEffect(() => {

    fetchProducts();
    // Added dependencies for clarity

  }, [
    page,
    query,
  ]);


  useEffect(() => {
    onkeyup = (e) => {
      if (e.key === "Enter") {
        fetchProducts();
      }
    };
  }, [searchInput]);


  // Generate list of active query filters for display
  const generateItemQueryList = (queryObj) => {
    if (!queryObj || typeof queryObj !== "object") return [];
    const list = [];
    Object.entries(queryObj).forEach(([key, value]) => {
      if (
        value == null ||
        (Array.isArray(value) && value.length === 0) ||
        ["unit", "category", "pageNumber", "pageSize"].includes(key)
      ) {
        return;
      }
      if (Array.isArray(value) && value.length > 1) {
        value.forEach((val) => {
          if (!list.find((item) => item.key === key && item.value === val)) {
            list.push({ key, value: val });
          }
        });
      } else {
        list.push({ key, value });
      }
    });
    return list;
  };

  // Handle search input change
  const handleInputChange = (e) => setSearchInput(e.target.value);

  return (
    <div className={styles.pageContainer}>
      {/* Sidebar Filter */}
      <div className={styles.filterContainer}>
        {selectedData && (
          <FilterQuery
            setQuery={setQuery}
            data={selectedData}
            pageN={page}
            setPageN={setPage}
            setMaxPage={setMaxPage}
            unit={unit}
            setUnit={setUnit}
            subCategory={id}
            setIsLoading={setIsLoading}
            setProductLoad={setProductLoad}
            setProducts={setProducts}
          />
        )}
      </div>

      {/* Main Content */}
      <div className={styles.contentContainer}>
        {/* Title, Unit Box, and Search */}
        <div className={styles.titleAndSearch}>
          <div className={styles.titleBox}>
            <h1>{title}</h1>
            {selectedData && renderUnitBox()}
          </div>
          <div className={styles.searchContainer}>
            <input
              className={styles.searchBox}
              type="text"
              placeholder={`Søg efter produkter i ${title}`}
              value={searchInput}
              onChange={handleInputChange}
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setTimeout(() => setIsInputFocused(false), 200)}
            />
            {isInputFocused && searchInput.trim() !== "" && (
              <SearchBarPopup query={fetchFromReg} search={searchInput} />
            )}
          </div>
        </div>

        {/* Active Query Filters */}
        <div className={styles.queryboxContainer}>
          {generateItemQueryList(query).map(({ key, value }) => (
            <FilterSortButton
              key={`${key}-${value}`}
              value={{ key, value }}
              setQuery={setQuery}
            />
          ))}
        </div>

        {/* Product List Section */}
        <div className={styles.productSection}>
          {productLoad ? (
            <div className={styles.loader}>
              <Loader sizex={150} />
            </div>
          ) : Array.isArray(products) && products.length > 0 ? (
            <>
              {/* Pagination Above */}
              <div className={styles.paginationContainer}>
                <Pagination
                  pageCount={maxPage}
                  forcePage={page - 1}
                  onPageChange={handlePageClick}
                />
              </div>

              {/* Product List */}
              <div className={styles.detailBoxGroup}>
                <div className={styles.loaderContainer}>
                  <MemoizedDetailBoxes
                    products={products}
                    Unit={unit}
                    category={encodeURIComponent(id)}
                  />
                  {isLoading && <div className={styles.overlay}></div>}
                </div>
              </div>

              {/* Pagination Below */}
              <div className={styles.paginationContainer}>
                <Pagination
                  pageCount={maxPage}
                  forcePage={page - 1}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          ) : (
            <p>No products matching current filter</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default KategoriDetailPage;
