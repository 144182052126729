import { use } from "react";
import { baseUrl, useGlobalContext } from "../../GlobalContext";
import { Navigate } from 'react-router-dom';




export const getprofileinfo = async () => {
  try {
    const response = await fetch(`${baseUrl}/user`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
      }
    });
    if (response.status === 401) {
      localStorage.removeItem('sessionToken');
      return {};

    }

    if (!response.ok) {
      throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching projects for SessionToken ${localStorage.getItem('sessionToken')}:`, error);
    return [];
  }
}


export async function setUserData(setUserType) {
  try {
    const token = localStorage.getItem("sessionToken");
    if (!token) return;
    const response = await fetch(`${baseUrl}/User`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      setUserType(data);
    } else {
      console.error("Error setting user data:", data.error);
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
}

export const resetPassword = async (data) => {
  console.log("Reset password data:", data);
  try {
    const response = await fetch(`${baseUrl}/User/reset-password`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    console.log("Reset password response:", response);

    if (!response.ok) {
      // Try to parse error body if any
      const errorBody = await response.json().catch(() => null);
      let errorMessage = `Fejl ved nulstilling af adgangskode: ${response.status || "Ukendt fejl"}`;

      if (response.status === 400) {
        errorMessage = errorBody?.message || "Ugyldig anmodning, tjek dine data.";
      } else if (response.status >= 500) {
        errorMessage = "Server fejl, prøv igen senere.";
      }

      console.error("Error response:", errorBody);
      throw new Error(errorMessage);
    }

    // Handle cases like 204 No Content (no JSON body)
    if (response.status === 204) {
      console.log("Reset password result: No content returned.");
      return { success: true, data: null };
    }

    // Otherwise, parse JSON if present
    let result;
    try {
      result = await response.json();
      console.log("Reset password result:", result);
      return result;
    } catch (parseError) {
      // If there's no JSON or parse fails, return success anyway
      console.warn("No JSON to parse or parse error:", parseError);
      return { success: true, data: null };
    }
  } catch (error) {
    console.error("Password reset failed:", error);
    return { success: false, error: error.message || "Ukendt fejl opstod." };
  }
};
