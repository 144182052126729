import { baseUrl } from "../../GlobalContext";
export const registerAccount = async (data) => {
    console.log(data);
    try {
        const response = await fetch(`${baseUrl}/User/create-account`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorBody = await response.json().catch(() => null);  // Try to parse response body if available
            let errorMessage = `Fejl ved registrering: ${response.status || "Ukendt fejl"}`;

            if (response.status === 409) {
                errorMessage = errorBody?.message || "E-mailen er allerede registreret.";
            } else if (response.status === 400) {
                errorMessage = errorBody?.message || "Ugyldig anmodning, tjek dine data.";
            } else if (response.status >= 500) {
                errorMessage = "Server fejl, prøv igen senere.";
            }

            throw new Error(errorMessage);
        }

        return await response.json();
    } catch (error) {
        console.error("Registration failed:", error.message);
        return { success: false, error: error.message || "Ukendt fejl opstod." };
    }
};


export const emailSignup = async (data) => {
    if (!data.email || !data.email.trim() || !data.email.includes('@')|| !data.email.includes('.')|| data.email.length < 5) {
        throw new Error("E-mail er påkrævet.");
    }

    if (!data.name || !data.name.trim()|| data.name.length < 2) {
        throw new Error("Navn er påkrævet.");
    }

    console.log(data);
    

    try {
        const response = await fetch(`${baseUrl}/User/User/mail-acceptance`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorBody = await response.json().catch(() => null);  // Try to parse response body if available
            let errorMessage = `Fejl ved registrering: ${response.status || "Ukendt fejl"}`;

            if (response.status === 409) {
                errorMessage = errorBody?.message || "E-mailen er allerede registreret.";
            } else if (response.status === 400) {
                errorMessage = errorBody?.message || "Ugyldig anmodning, tjek dine data.";
            } else if (response.status >= 500) {
                errorMessage = "Server fejl, prøv igen senere.";
            }

            throw new Error(errorMessage);
        }

        return await response.json();
    } catch (error) {
        console.error("Registration failed:", error.message);
        return { success: false, error: error.message || "Ukendt fejl opstod." };
    }
}
    

export const registerUnderAccount = async (data) => {
    try {
        const response = await fetch(`${baseUrl}/User/register/under-account`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorBody = await response.json().catch(() => null);  // Try to parse response body if available
            let errorMessage = `Fejl ved registrering: ${response.status || "Ukendt fejl"}`;

            if (response.status === 409) {
                errorMessage = errorBody?.message || "E-mailen er allerede registreret.";
            } else if (response.status === 400) {
                errorMessage = errorBody?.message || "Ugyldig anmodning, tjek dine data.";
            } else if (response.status >= 500) {
                errorMessage = "Server fejl, prøv igen senere.";
            }

            throw new Error(errorMessage);
        }

        return await response;
    }
    catch (error) {
        console.error("Registration failed:", error.message);
        return { success: false, error: error.message || "Ukendt fejl opstod." };
    }
}

