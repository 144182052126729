export const vilkarText = {
  HelpPageContents: {
    TermsAndConditions: {
      label: "Terms and Conditions",
      subcategories: [
        { key: "Introduction" },
        { key: "InformationAndUpdates" },
        { key: "SubscriptionsAndPayment" },
        { key: "EUCompliance" },
        { key: "TextAndDataMining" },
        { key: "PersonalDataProcessing" },
        { key: "IntellectualProperty" },
        { key: "LimitationOfLiability" },
        { key: "Termination" },
        { key: "ModificationOfTnCs" },
        { key: "GoverningLaw" },
      ],
      content: {
        Introduction: {
          label: "Introduction",
          paragraphs: [
            {
              text: "Last updated: February 19, 2025",
            },
            {
              text: "Welcome to Buildtivity. The platform is a service provided by Buildtivity ApS, a Danish company with VAT no. DK43976486, located at Hadsundvej 86C, 2. tv, 9000 Aalborg, Denmark (referred to as '**we**' or '**us**').",
            },
            {
              text: "If you wish to reach out to us, please do so by e-mail at info@buildtivity.dk.",
            },
            {
              text: "These Terms and Conditions (**T&Cs**) govern the use of our platform by business users, including manufacturers, architects, contractors, engineers, and other construction professionals. Non-business users are not allowed access, except for students under specific conditions.",
            },
            {
              text: "**IMPORTANT**: By registering an account, accessing, or using Buildtivity, you agree to be bound by these T&Cs.",
            },
          ],
        },
        InformationAndUpdates: {
          label: "Information and Updates",
          paragraphs: [
            {
              text: "**Accuracy of information**: While we strive to ensure the accuracy and completeness of the information available on the platform, Buildtivity does not guarantee that the information is error-free, complete or up to date. Users are encouraged to verify the technical specifications and suitability of the materials for their specific projects on an ongoing basis.",
            },
            {
              text: "**Incorrect information**: If we become aware that information available on the platform is, in our sole discretion, incorrect, for instance because it is outdated, has been wrongly uploaded or configured by you etc., we reserve the right to delete or correct said information, notifying you in advance. If you want our assistance in correcting the incorrect information, you may request so and in such case our time spent on the assistance will be billed by the hour according to our at that time applicable hourly fee. We will strive, but are not obligated, to provide a prior estimate of our fees for said assistance, which, in any case, is non-binding.",
            },
            {
              text: "**No warranty or liability**: Buildtivity is provided on an **as-is** and **as-available** basis. To the fullest extent permitted by law, we exclude all warranties, express or implied, regarding the accuracy, reliability or completeness of the information provided on the platform. We do not accept liability for any loss or damage, whether direct, indirect or consequential, arising from the use or reliance on any information available at any time on the platform.",
            },
          ],
        },
        SubscriptionsAndPayment: {
          label: "Subscriptions & Payment",
          paragraphs: [
            {
              text: "**Subscriptions**: Subscriptions are offered on different terms. Price, special terms and included functionality are specified in the marketing material or on the order confirmation. ",
            },
            {
              text: "**Payment**: Subscription fees are charged **excluding VAT** and paid monthly in advance.",
            },
            {
              text: "**Price adjustments**: We may adjust pricing of our subscriptions once annually giving you at least the same notice as that under clause 8.2 below. Price adjustments must not exceed 5 percent of the price in effect at the time of adjustment.", 
            },
          ],
        },
        EUCompliance: {
          label: "EU Platform Compliance",
          paragraphs: [
            {
              text: "**Transparency of ranking (Article 5(2))**: The platform's ranking criteria for search results are based on several factors including relevance, completeness of the material data provided, and user interaction with the content. A more detailed description of the main parameters determining ranking on the UUID of the EPD",
            },
            {
              text: "**Differentiated treatment (Article 5(5))**: The platform does not differ treatment of business users, other than distinctions arising from the type of account (ie manufacturer or other business users) and functionality limitations or enhancements linked to paid features.",
            },
            {
              text: "**Account restrictions (Articles 7(2) and 7(3))**: We reserve the right to restrict, suspend or terminate the account of any business user if they breach these T&Cs, fail to make payment or misuse the platform. Business users will be notified of any intended restriction, suspension or termination with reasons for such action prior to the enforcement of such measures except where urgent action is required. Users have the right to request reinstatement by contacting us at the information mentioned in the start of these T&Cs.",
            },
          ],
        },
        TextAndDataMining: {
          label: "Text and Data Mining Opt-Out",
          paragraphs: [
            {
              text: "Business users and anyone else accessing the platform directly or indirectly expressly agree that they are not permitted to perform text and data mining on or in relation to its content. This opt-out provision applies notwithstanding any rights under the DSM Directive (directive 2019/790). Users who wish to engage in text and data mining must obtain prior explicit written consent from us. ",
            }
            
          ],
        },
        PersonalDataProcessing: {
          label: "Processing of Personal Data",
          paragraphs: [
            {
              text: "When you register an account to access the platform, we process personal data (as data controller) about you. For more information on how we process your personal data, including your rights and how to exercise them, please refer to our privacy policy.", 
            
            },
          ],
        },
        IntellectualProperty: {
          label: "Intellectual Property Rights",
          paragraphs: [
            {
              text: "**We reserve all rights**: We reserve all intellectual property rights, including to any trade secrets knowhow and similar, in and to the platform. No provision under the T&Cs shall constitute a transfer of such rights to you. "            },
            {
              text: "**Platform data**: We reserve all rights in and to data uploaded and/or stored in the platform. When you upload data to the platform, you acknowledge that we may use such data for our own commercial purposes, including making it available through the platform to third parties. Insofar you are the proprietor of intellectual property rights in or to the data, you hereby grant us a non-exclusive, irrevocable, global, fully paid up, sublicensable and transferrable license to said data. This right persists after termination of our contractual relationship.",
            },
            {
              text: "**License to use the platform**: Upon full and timely payment, you receive a revocable, non-exclusive, non-sublicensable and non-transferable license to use the platform in accordance with the T&Cs. License are provided on an individual, per user basis and is suspended in case of non-payment.",
            },
          ],
        },
        LimitationOfLiability: {
          label: "Limitation of Liability",
          paragraphs: [
            {
              text: "**General exclusion**: We are not liable for any losses incurred by business users or third parties, including but not limited to loss of profits, revenue, business opportunities or data, whether arising in contract, tort (including negligence) or otherwise, in connection with use of the platform or reliance on any information provided.",
            },
            {
              text: "**Indirect and consequential loss**: We shall not be liable for any indirect or consequential losses or damages, including any loss arising from any reliance placed on information or materials made available through the platform.",
            },
          ],
        },
        Termination: {
          label: "Termination",
          paragraphs: [
            {
              text: "**Termination for breach**: We may terminate the T&Cs and suspend access to the platform without notice and with immediate effect if, in our reasonable opinion, you have acted in breach of the T&Cs. ",
            },
            {
              text: "**Subscription termination**: You may terminate your subscription with effect on the next subscription payment.",
            },
            {
              text: "**No refunds**: Termination does not give the right to reclaim any payments already made. ",
            },
          ],
        },
        ModificationOfTnCs: {
          label: "Modification of Terms & Conditions",
          paragraphs: [
            {
              text: "We reserve the right to modify these T&Cs at any time. Any modifications will be communicated to business users via email or platform notification, and continued use of the platform following such modifications shall constitute acceptance of the revised T&Cs. In case of material changes, the affected users will be at least the same notice as that under clause 8.2 above before the changes take effect.",
            },
            
          ],
        },
        GoverningLaw: {
          label: "Governing Law & Jurisdiction",
          paragraphs: [
            {
              text: "These T&Cs are governed by and construed in accordance with the laws of Denmark. Any disputes arising out of or in connection with these T&Cs shall be subject to the exclusive jurisdiction of the courts of Denmark with the court of Aalborg as first instance.",
            },
          ],
        },
      },
    },
  },
};
