// DetailPage.js

import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import './DetailPage.css';
import MaterialTable from './tabel.js';
import { DropdownAndModal } from '../../components/Utils/dropdownmodal';
import { fetchItemData } from '../../components/APIS/categoryAPI';
import { AiOutlineFilePdf } from 'react-icons/ai';
import Loader from '../../components/Utils/Loader.js';


function generateTableData(product, selectedUnitType) {
  if (!product || !product.declaredUnits || product.declaredUnits.length === 0) {
    return { categories: [], rows: [], units: [], selectedUnit: null, safetyLevel: null };
  }

  // Create a set to gather all unique unit names
  const uniqueUnits = new Set();

  // Filter the declaredUnits to remove duplicates or units without a name
  const uniqueDeclaredUnits = product.declaredUnits.filter(unit => {
    if (!unit.unit || uniqueUnits.has(unit.unit)) {
      return false;
    }
    uniqueUnits.add(unit.unit);
    return true;
  });

  // A set to gather all unique module names for columns
  const uniqueModules = new Set();

  // Collect all unique modules from environmental impacts
  uniqueDeclaredUnits.forEach(unit => {
    unit.environmentalImpacts?.forEach(impact => {
      impact.values.forEach(valueObj => uniqueModules.add(valueObj.module));
    });
  });

  // Convert the set to an array
  let modulesArray = Array.from(uniqueModules);

  // Ensure that "A1A3" is the first module if present
  const a1a3Index = modulesArray.indexOf("A1A3");
  if (a1a3Index > -1) {
    modulesArray = ["A1A3", ...modulesArray.filter(module => module !== "A1A3")];
  }

  modulesArray.sort((a, b) => {
    // Ensure 'A1A3' or 'A1-A3' is always first
    if (a === "A1A3" || a === "A1-A3") return -1;
    if (b === "A1A3" || b === "A1-A3") return 1;

    // Extract prefix and number from the module names
    const regex = /^([A-Za-z]+)(\d+)?$/;
    const aMatch = a.match(regex);
    const bMatch = b.match(regex);

    if (!aMatch || !bMatch) {
      return a.localeCompare(b);
    }

    const [, aPrefix, aNumber] = aMatch;
    const [, bPrefix, bNumber] = bMatch;

    if (aPrefix !== bPrefix) return aPrefix.localeCompare(bPrefix);
    if (aNumber && bNumber) return parseInt(aNumber, 10) - parseInt(bNumber, 10);
    if (aNumber) return -1;
    if (bNumber) return 1;

    return a.localeCompare(b);
  });

  // Create categories including "Parameter", "Unit", and sorted module names
  let categories = ["Parameter", "Unit", ...modulesArray];

  // List of all available units (after filtering duplicates)
  const units = uniqueDeclaredUnits.map(unit => unit.unit || "N/A");

  // Function to find the preferred unit, sorted by highest safety level
  const findPreferredUnit = () => {
    // Use a shallow copy to avoid mutating the original array during sort
    const sortedUnits = [...uniqueDeclaredUnits].sort(
      (a, b) => (b.safetyLevel || 0) - (a.safetyLevel || 0)
    );

    if (selectedUnitType) {
      return sortedUnits.find(unit => unit.unit === selectedUnitType) || null;
    }
    return sortedUnits.length > 0 ? sortedUnits[0] : null;
  };

  // Get the selected unit
  const selectedUnit = findPreferredUnit();

  if (!selectedUnit) {
    console.warn("Selected unit is undefined.");
    return { categories, rows: [], units, selectedUnit: null, safetyLevel: null };
  }

  // Extract safety level for the selected unit
  const safetyLevel = selectedUnit.safetyLevel || "N/A";

  // Generate the rows for the selected unit
  let rows = selectedUnit.environmentalImpacts.map(impact => {
    const row = [impact.parameter || "N/A", impact.unit || "N/A"];
    modulesArray.forEach(module => {
      const valueObj = impact.values.find(v => v.module === module);
      row.push(valueObj ? valueObj.value : "N/A");
    });
    return row;
  });

  // ----- Remove columns that are full of "N/A" (or null) -----
  // We always keep the first two columns ("Parameter" and "Unit")
  const numCols = categories.length;
  const keepIndices = [];
  for (let col = 0; col < numCols; col++) {
    if (col < 2) {
      keepIndices.push(col);
    } else {
      // Check if at least one row has a non-"N/A" (and non-null) value in this column
      const hasValue = rows.some(row => row[col] !== "N/A" && row[col] != null);
      if (hasValue) {
        keepIndices.push(col);
      }
    }
  }

  // Filter the categories and rows to only include the kept columns
  categories = keepIndices.map(i => categories[i]);
  rows = rows.map(row => keepIndices.map(i => row[i]));
  // ---------------------------------------------------------

  // console.log(rows);
  return { categories, rows, units, selectedUnit, safetyLevel };
}

function DetailPage() {
  const { id } = useParams();
  const location = useLocation();
  const initialData = useMemo(() => location.state?.data || {}, [location.state]);
  const [product, setProduct] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUnitType, setSelectedUnitType] = useState(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [selectedTable, setSelectedTable] = useState("Environmental Impact");
  const [Image, setImage] = useState();
  const params = new URLSearchParams(location.search);
  const from = params.get('from');
  useEffect(() => {
    setIsLoading(true);

    fetchItemData(id)
      .then(data => {
        if (!data || data.error || data.status === 404) {
          // If no product data is found or a 404 status is returned, handle accordingly
          setProduct(null);
          setIsLoading(false);
          console.error('Product not found or error fetching product data.');
          return;
        }

        setProduct(data);

        // Generate table data to find the preferred unit
        const environmentalImpactData = generateTableData(data, null);
        const preferredUnit = environmentalImpactData.selectedUnit?.unit || null;

        // Set the selected unit type to the preferred unit
        setSelectedUnitType(preferredUnit);

        setIsLoading(false);

        // Check if there are images to display
        // console.log(data);
        // if (
        //   (data.manualTypedData &&
        //   data.manualTypedData.producentUploadData &&
        //   data.manualTypedData.producentUploadData.imageURLS &&
        //   data.manualTypedData.producentUploadData.imageURLS.length > 0) 
        // ) {
        //   setImage(data.manualTypedData.producentUploadData.imageURLS[0].url);
        //   setImage(data.manualTypedData.epdfrontpageURL);
        // }
        if (data.manualTypedData && data.manualTypedData.producentUploadData && data.manualTypedData.producentUploadData.imageURLS && data.manualTypedData.producentUploadData.imageURLS.length > 0) {
          setImage(data.manualTypedData.producentUploadData.imageURLS[0].url);
        } else if (data.manualTypedData && data.manualTypedData.epdFrontPageURL) {
          setImage(data.manualTypedData.epdFrontPageURL);
        }
      })
      .catch(err => {
        console.error(err);
        setIsLoading(false);
        setProduct(null); // Set product to null on error
      });
  }, [id]);



  const certificationImages = {
    "CE": "/images/ce.svg",
    "Cradle_to_cradle": "/images/CradletoCradle.svg",
    "EU_Ecolabel": "/images/euecolabelpng.png",
    "FSC": "/images/fsc-maerket.svg",
    "indeklima_mark": "/images/indeklimamaerke.svg",
    "PEFC": "/images/PEFC.svg",
    "Svanemarke": "/images/svanemærke.svg",
    "allergy_uk": "/images/allergy_uk.jpg",
    "astmadanamrk": "/images/astmadanmark.jpg",
    "derblueengel": "/images/Blauer_Engel_logo.svg",
  };

  const technicalSpecsData = useMemo(() => {
    if (!product?.manualTypedData?.producentUploadData?.tekniskSpecifikation) {
      return { categories: [], rows: [] };
    }



    const categories = ['Parameter', 'Unit', 'Value'];
    const rows = product.manualTypedData.producentUploadData.tekniskSpecifikation.map(spec => {
      const parameter = spec.parameter || 'N/A';
      const value = spec.value || 'N/A';
      const unit = spec.unit || 'N/A';
      return [parameter, unit, value];
    });

    return { categories, rows };
  }, [product]);
  
  const materialsData = () => {
    let prod = product.manualTypedData;
    if (!prod || !Array.isArray(prod.materials)) {
      return { categories: [], rows: [] };
    }

    const categories = ['Material Name', 'Kg', 'Percentage'];

    const rows = prod.materials.map(material => {
      const name = material.materialName || 'N/A';
      const kg = material.kg !== undefined ? String(material.kg) : 'N/A';
      const percentage = material.percentage !== undefined ? String(material.percentage) : 'N/A';
      return [name, kg, percentage];
    });

    return { categories, rows };
  };

  const masseFyldeData = () => {
    let prod = product.manualTypedData;
    if (!prod || !Array.isArray(prod.masseFylde)) {
      return { categories: [], rows: [] };
    }

    const categories = ["Volume", "Density", "Thickness", "Weight"];

    const rows = prod.masseFylde.map(massefylde => {
      const volume = massefylde.volume || 'N/A';
      const density = massefylde.density || 'N/A';
      const thickness = massefylde.thickness || 'N/A';
      const weight = massefylde.weight || 'N/A';

      return [volume, density, thickness, weight];
    });

    return { categories, rows };
  };

  const renderCertifications = () => {
    const certificationMap = {
      1: "CE",
      2: "Svanemarke",
      3: "Cradle_to_cradle",
      4: "indeklima_mark",
      5: "FSC",
      6: "PEFC",
      7: "EU_Ecolabel",
      8: "derblueengel",
      9: "astmadanmark",
      10: "allergy_uk",
    };

    const cert = () => {
      try {
        const certifications = product?.manualTypedData?.producentUploadData?.certifications;
        if (certifications && Array.isArray(certifications)) {
          return certifications.filter((item) => item.url !== null);
        }
        return [];
      } catch (error) {
        console.error("Error fetching certifications:", error);
        return [];
      }
    };


    const certifications = cert();

    return (
      <React.Fragment>
        {certifications.map((certification) => {
          const certificationKey = certificationMap[certification];
          if (certificationKey) {
            return (
              <img
                key={certificationKey}
                src={certificationImages[certificationKey]}
                alt={certificationKey}
                className="smallImagePlaceholderStyle_detail"
              />
            );
          }
          return null;
        })}
      </React.Fragment>
    );
  };

  const environmentalImpactData = generateTableData(product, selectedUnitType);
  const tableData = {
    "Environmental Impact": environmentalImpactData,
    "Materials": materialsData(),
    "Massefylde": masseFyldeData(),
    "Technical Specifications": technicalSpecsData,
  };

  if (product === null) {
    return (
      <div>
        <p>Product not found</p>
      </div>
    );
  }

  return (
    <div className="page-container_detail">
      <div className="detail-container_detail">
        
        <div className="largeImageStyle_detail">
          <img
            className="detailpage-Image"
            style={{ width: '350px', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)' }}
            src={Image}
          />
        </div>

        <div className="detail-content_detail">
          <div>
            <h1>{product.name}</h1>
          </div>
          <div>
            <DropdownAndModal ItemId={id} category={from} />
          </div>

          <div className="about-product">
            <strong>Producent:  </strong> &nbsp;
            <span style={{ fontFamily: 'GOTHIC', fontWeight: 'normal' }}>
               {product.producerName}
            </span>
          </div>

          <div className="about-product">
            <strong>Kategori:</strong> &nbsp;
            <span style={{ fontFamily: 'GOTHIC', fontWeight: 'normal' }}>
              {product.manualTypedData && product.manualTypedData.categories && product.manualTypedData.categories.length > 0
                ? product.manualTypedData.categories.join(', ')
                : 'N/A'}
            </span>
          </div>

          <div className='EPDLink'>
            EPD Link:&nbsp;
            <a href={product.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <AiOutlineFilePdf style={{ fontSize: '24px', color: 'red' }} />
            </a>
          </div>

          {/* Product Description */}
          <div className="productDescription">
            {isLoading ? (
              <Loader sizex={24} colors="Green" />
            ) : (
              <>
                <p>
                  {product.manualTypedData && product.manualTypedData.description
                    ? isDescriptionExpanded
                      ? product.manualTypedData.description
                      : `${product.manualTypedData.description.slice(0, 100)}${product.manualTypedData.description.length > 100 ? '...' : ''}`
                    : ''}
                </p>
                {product.manualTypedData && product.manualTypedData.description && product.manualTypedData.description.length > 100 && (
                  <button
                    className="expandButton"
                    onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                  >
                    {isDescriptionExpanded ? 'Læs mindre' : 'Læs mere'}
                  </button>
                )}
              </>
            )}
          </div>

          <div style={{ marginTop: '50px' }}>
            {isLoading ? <p>Loading...</p> : renderCertifications()}
          </div>
        </div>
      </div>

      <div className="table-selector">
        {Object.keys(tableData).map((tableName) => (
          <button
            key={tableName}
            onClick={() => setSelectedTable(tableName)}
            className={selectedTable === tableName ? 'active' : ''}
          >
            {tableName}
          </button>
        ))}
      </div>

      {selectedTable && (
        <MaterialTable
          title={selectedTable}
          data={tableData[selectedTable] || {}}
          selectedUnit={selectedUnitType}
          onUnitChange={setSelectedUnitType}
          safetyLevel={environmentalImpactData.safetyLevel}
          
        />
      )}
    </div>
  );
}

export default DetailPage;