import { baseUrl } from "../../GlobalContext";

export const fetchBranchDetailsById = async (id) => {
  const types = ["Branche data", "Generisk data", "Standardværdi"];
  let products = [];

  for (const type of types) {
    try {
      const encodedType = encodeURIComponent(type); // Encode the type
      const response = await fetch(
        `${baseUrl}/products/products-by-category/${id}?Type=${encodedType}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch products: " + response.statusText);
      }
      const data = await response.json();
      // console.log(`Data for type ${type}:`, data);

      products = products.concat(data);
    } catch (error) {
      console.error(error);
    }
  }
  return products;
};

export const fetchItemData = async (itemTitle) => {
  try {
    const response = await fetch(`${baseUrl}/Products/${itemTitle}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        `Server responded with ${response.status}: ${response.statusText}`
      );
    }

    const data = await response.json();
    // console.log(`Data for item ${itemTitle}:`, data);
    return data;
  } catch (error) {
    console.error(`Error fetching item ${itemTitle}:`, error);
    return {};
  }
};

export const fetchAll = async () => {
  try {
    const response = await fetch(`${baseUrl}/Products`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch products: " + response.statusText);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

export const fetchAllCategories = async () => {
  try {
    const response = await fetch(`${baseUrl}/Products/all-categories`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch categories: " + response.statusText);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    return [];
  }
};

export const fetchSubcategories = async () => {
  try {
    const response = await fetch(`${baseUrl}/Products/GetSubCategories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch subcategories: " + response.statusText);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching subcategories:", error);
    return [];
  }
};

export const fetchQueryDataCat = async (Cat) => {
  // console.log(Cat.id);
  // console.log(`|${baseUrl}/FilterData/filter-data/category?category=${Cat.id}|`)
  try {
    const response = await fetch(
      `${baseUrl}/FilterData/filter-data/category?category=${Cat.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch query data: " + response.statusText);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching query data:", error);
    return [];
  }
};

export const fetchDatafromQuery = async (query) => {
  // console.log(query);
  try {
    const response = await fetch(`${baseUrl}/Products/products-query`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
      },
      body: JSON.stringify(query)
    });

    // console.log(JSON.stringify(query));

    if (!response.ok) {
      throw new Error("Failed to fetch query data: " + response.statusText);
    }
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching query data:", error);
    return [];
  }
};
export const fetchFromReg = async (query, category,offset = 0, isSubcategory = false, isProducer = false, isCategory = false) => {
  try {
    let first, reg;
    if (isCategory || isSubcategory || isProducer) {
      first = category;
      reg = query;
    } else {
      first = query;
      reg = "";
    }

    const response = await fetch(
      `${baseUrl}/Products/GetProductsRegex?query=${encodeURIComponent(first)}&offset=${offset}&isSubcategory=${isSubcategory}&isProducer=${isProducer}&isCategory=${isCategory}&regex=${encodeURIComponent(reg)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }
    );

    if (!response.ok) {
      if (response.status === 404) {
        return [];
      }
      throw new Error("Failed to fetch query data: " + response.statusText);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching query data:", error);
    return [];
  }
};

export const fetchQueryDataReg = async (search, offset = 0, isSubcategory = false, isProducer = false, isCategory = false, filterValue) => {
  try {
    const response = await fetch(`${baseUrl}/Products/GetProductsRegexFilterData?query=${encodeURIComponent(search)}&offset=${offset}&isSubcategory=${isSubcategory}&isProducer=${isProducer}&isCategory=${isCategory}&regex=`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('sessionToken')}`
      }
    }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch query data: " + response.statusText);
    }

    const data = await response.json(); // ✅ Properly awaiting JSON
    return data;
  } catch (error) {
    console.error("Error fetching query data:", error);
    return [];
  }
};
