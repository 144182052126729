import { baseUrl } from "../../GlobalContext";

export const fetchProducers = async () => {
  try {
    const response = await fetch(
      `${baseUrl}/info/getAllDistinctProducerNames`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch Producers: " + response.statusText);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching Producers:", error);
    return [];
  }
};
