import React, { useState } from "react";
import { resetPassword } from "../../components/APIS/ProfileAPI";
import { useParams } from "react-router-dom";
import styles from "./passwardReset.module.css";
import { useNavigate } from "react-router-dom";
function PasswardReset() {
  // email, oldPassword, newPassword
  const [formData, setFormData] = useState({
    email: "",
    oldPassword: "",
    newPassword: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      const response = await resetPassword(formData);
      if (response.success) {
        setSuccess("Password reset successfully.");
        navigate("/login");
      } else {
        setError(response.error || "An error occurred.");
      }
    } catch (error) {
      setError(error.message || "An error occurred.");
    }
  }
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Reset Password</h1>
      <form
        className={styles.form}
        onSubmit={async (e) => {
            handleSubmit(e);            
        
          try {
            const response = await resetPassword(formData);
            if (response.success) {
              setSuccess("Password reset successfully.");
            } else {
              setError(response.error || "An error occurred.");
            }
          } catch (error) {
            setError(error.message || "An error occurred.");
          }
        }}
      >
        <h3 className={styles.label}>E-mail</h3>
        <input
          type="email"
          placeholder="E-mail"
          value={formData.email}
          onChange={(e) =>
            setFormData({ ...formData, email: e.target.value })
          }
          className={styles.input}
        />
        <h3 className={styles.label}>Old Password</h3>
        <input
          type="password"
          placeholder="Given Password"
          value={formData.oldPassword}
          onChange={(e) =>
            setFormData({ ...formData, oldPassword: e.target.value })
          }
          className={styles.input}
        />
        <h3 className={styles.label}>New Password</h3>
        <input
          type="password"
          placeholder="New Password"
          value={formData.newPassword}
          onChange={(e) =>
            setFormData({ ...formData, newPassword: e.target.value })
          }
          className={styles.input}
        />
        <button type="submit" className={styles.button}>
          Reset Password
        </button>
      </form>
      {error && <p className={styles.error}>{error}</p>}
      {success && <p className={styles.success}>{success}</p>}
    </div>
  );
}

export default PasswardReset;

